import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ChatContainer from './ChatContainer';
import Sidebar from './Sidebar';
import './ChatPage.css';
import WebSocketInstance from './WebSocketInstance';

function ChatPage({ currentUser, signOut }) {
    const { contactphonenumber, name } = useParams();
    const decodedName = decodeURIComponent(name);
    const contactsWebSocketRef = useRef(null);
    const chatWebSocketRef = useRef(null);

    const [currentChatRecipient, setCurrentChatRecipient] = useState(() => {
        return localStorage.getItem('currentChatRecipient') || null;
    });

    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);

    useEffect(() => {
        if (!contactsWebSocketRef.current && currentUser) {
            contactsWebSocketRef.current = new WebSocketInstance(currentUser.organization);
        }
    }, [currentUser]);

    useEffect(() => {
        if (contactphonenumber && currentUser) {
            if (chatWebSocketRef.current) {
                chatWebSocketRef.current.close();
            }
            chatWebSocketRef.current = new WebSocketInstance(currentUser.organization, contactphonenumber);
            setCurrentChatRecipient(contactphonenumber);
        }
    }, [contactphonenumber, currentUser]);

    useEffect(() => {
        localStorage.setItem('currentChatRecipient', currentChatRecipient);
    }, [currentChatRecipient]);

    const handleContactsUpdate = useCallback((fetchedContacts) => {
        setContacts(fetchedContacts);
    }, []);

    const handleContactClick = useCallback((contact) => {
        setSelectedContact(contact);
        setCurrentChatRecipient(contact.phoneNumber);
    }, []);

    return (
        <div className='chatpage'>
            <div className='chatpage-container'>
                <Sidebar
                    currentUser={currentUser}
                    signOut={signOut}
                    currentChatRecipient={currentChatRecipient}
                    webSocket={chatWebSocketRef.current}
                    onContactsUpdate={handleContactsUpdate}
                    onContactClick={handleContactClick}
                />
                <ChatContainer
                    currentUser={currentUser}
                    setCurrentChatRecipient={setCurrentChatRecipient}
                    webSocket={chatWebSocketRef.current}
                    selectedContact={selectedContact}
                />
            </div>
        </div>
    );
}

export default React.memo(ChatPage);
