// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebase from 'firebase';


const firebaseConfig = {
  apiKey: "AIzaSyDMJ8AXIoYmTyF3vNcRorRRonTSvmShlu4",
  authDomain: "gambot-a383d.firebaseapp.com",
  projectId: "gambot-a383d",
  storageBucket: "gambot-a383d.appspot.com",
  messagingSenderId: "537485039696",
  appId: "1:537485039696:web:e6fd6a6c7228d22f545955",
  measurementId: "G-TP7S381TZ9"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const app = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const db = app.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();




export { auth, googleProvider };

export default db;