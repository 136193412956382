import React, { useState } from 'react';
import db, { auth, googleProvider } from '../firebase';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import './Login.css';
import WebSocketInstance from './WebSocketInstance';

const Login = ({ setUser, setWebSocket }) => { // Receive setWebSocket as prop
  const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signInWithEmailAndPassword = () => {
        axios
          .post("https://gambot.azurewebsites.net/api/Webhooks/authenticate", {
            "username": email,
            "password": password
          })
          .then((response) => {
            console.log("Response:", response.data);
            const userCredential = response.data.userCredential;
            const user = response.data.userData;
            // Check for successful response here if needed
            const newUser = {
                fullname: user.UserName, // This might be null, handle accordingly
                email: user.UserEmail,
                photoURL: userCredential.photoURL,
                userId: userCredential.Uid, // UID from Firebase Authentication,
                organization: user.Organization,
                wabaNumber : user.wabaNumber,
            };
            const webSocket = new WebSocketInstance(newUser.organization);
            setWebSocket(webSocket); // Pass WebSocket instance to parent component
            navigate('/Contacts');
            // navigate('/chat/' + user.wabaNumber);
            localStorage.setItem('user', JSON.stringify(newUser));
          })    
          .catch((error) => {
            console.error("Axios Error:", error);
      
            // Handle the error here, you can access error.response for more details
            if (error.response) {
              console.error("Server Response Data:", error.response.data);
              console.error("Server Response Status:", error.response.status);
            }
          });
      };
      
    return (
        <div className='login'>
            <div className='login-container'>
                <img src='./GamBot_Logo.png' alt='' className='login-logo' />
                <p className='login-name'>Gambot Real Time Chat</p>
                <div>
                    <input 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <br></br>
                    <button onClick={signInWithEmailAndPassword}>
                        Sign in
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
