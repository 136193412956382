import React, { useState } from 'react';
import './ButtonOptions.css';
import LaunchIcon from '@mui/icons-material/Launch';
import PhoneIcon from '@mui/icons-material/Phone';

const ButtonOptions = ({ buttons, onAddButton, onRemoveButton }) => {
  const [buttonType, setButtonType] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');
  const [additionalInput, setAdditionalInput] = useState('');

  const handleAddButtonClick = () => {
    if (buttonType && buttonLabel) {
      if ((buttonType === 'URL' || buttonType === 'PHONE_NUMBER') && !additionalInput) {
        alert('You must provide the URL or phone number.');
        return;
      }
      const newButton = { type: buttonType, label: buttonLabel };
      if (buttonType === 'URL') {
        newButton.url = additionalInput;
      } else if (buttonType === 'PHONE_NUMBER') {
        newButton.phone_number = additionalInput;
      }
      onAddButton(newButton);
      setButtonType('');
      setButtonLabel('');
      setAdditionalInput('');
    } else {
      alert('You must choose a button type and provide a label.');
    }
  };

  return (
    <div className="button-options">
      <h2>Buttons</h2>
      <p className="button-text-p">Create buttons that let customers respond to your message or take action.</p>

      <div className="add-button-section">
        <select
          value={buttonType}
          onChange={(e) => setButtonType(e.target.value)}
          placeholder="Select button type"
        >
          <option value="">Select Type</option>
          <option value="Quick Reply">Custom</option>
          <option value="URL">Visit Website - 2 Button Maximum</option>
          <option value="PHONE_NUMBER">Phone Call Number - 1 Button Maximum</option>
        </select>
        <input
          type="text"
          value={buttonLabel}
          onChange={(e) => setButtonLabel(e.target.value)}
          placeholder="Enter button label"
        />
        {(buttonType === 'URL' || buttonType === 'PHONE_NUMBER') && (
          <input
            type="text"
            value={additionalInput}
            onChange={(e) => setAdditionalInput(e.target.value)}
            placeholder={buttonType === 'URL' ? 'Enter URL' : 'Enter phone number'}
          />
        )}
        <button className="button-options-add-button" onClick={handleAddButtonClick}>Add</button>
      </div>
      {buttons.length > 0 && (
        <ul className="buttons-list">
          {buttons.map((button, index) => (
            <li key={index} className="button-item">
              <span>
                {button.label} ({button.type})
                {button.type === 'URL' && <LaunchIcon className="button-icon" />}
                {button.type === 'PHONE_NUMBER' && <PhoneIcon className="button-icon" />}
              </span>
              <button className="button-options-remove" onClick={() => onRemoveButton(index)}>Remove</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ButtonOptions;
