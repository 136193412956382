import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContactFilterRow from './ContactFilterRow';
import './ContactFilter.css';

const ContactFilter = ({ organization, onFiltersDataChange, contactsFilter }) => {
    const [filters, setFilters] = useState(contactsFilter || []);
    const [firstRow, setFirstRow] = useState([]);
    const [secondRow, setSecondRow] = useState([]);
    const [filtersData, setFiltersData] = useState([]);

    const handleRowsDataChange = (data, index) => {
        if (index === 0) {
            setFirstRow(data);
        } else if (index === 1) {
            setSecondRow(data);
        }
    };

    useEffect(() => {
        if (firstRow.length > 0 && secondRow.length > 0) {
            setFiltersData([firstRow, secondRow]);
        } else if (firstRow.length > 0) {
            setFiltersData([firstRow]);
        }
    }, [firstRow, secondRow]);

    useEffect(() => {
        onFiltersDataChange(filtersData);
    }, [filtersData, onFiltersDataChange]);

    const handleAddFilter = (e) => {
        e.preventDefault(); // Prevent form submission
        if (filters.length < 2) {
            setFilters([...filters, { group: '', operator: filters.length === 0 ? 'equals' : 'notEquals' }]);
        }
    };

    const handleDeleteFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };

    return (
        <div className='contactFilter-container'>
            <h3 className='contactFilter-header'>Filter Contacts By Group</h3>
            <table className='contactFilter-tbl'>
                <tbody>
                    {filters.map((filter, index) => (
                        <ContactFilterRow
                            key={index}
                            filter={filter}
                            onDelete={() => handleDeleteFilter(index)}
                            organization={organization}
                            onRowsDataChange={(data) => handleRowsDataChange(data, index)}
                            firstRow={firstRow}
                            secondRow={secondRow}
                        />
                    ))}
                </tbody>
            </table>
            {filters.length < 2 && (
                <button onClick={handleAddFilter}>Add Filter</button>
            )}
        </div>
    );
};

export default ContactFilter;
