import React, { useEffect, useRef, useState, useCallback } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertEmoticon from '@mui/icons-material/InsertEmoticon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import Picker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import StartConversationByTemplate from './Templates/startConversationByTemplate';
import './ChatContainer.css';
import axios from 'axios';

const ChatContainer = ({ currentUser, setCurrentChatRecipient, webSocket, selectedContact }) => {
    const [message, setMessage] = useState('');
    const [openEmojiBox, setOpenEmojiBox] = useState(false);
    const { contactphonenumber } = useParams();
    const [chatMessages, setChatMessages] = useState([]);
    const chatBox = useRef(null);
    const [IsConversationLiveByPhoneNumber, setIsConversationLiveByPhoneNumber] = useState(false);
    const [isRecipientReplyLast24Hours, setisRecipientReplyLast24Hours] = useState(false);
    const [showTemplatesHandler, setShowTemplatesHandler] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const fetchChatMessages = useCallback(async (phoneNumber) => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetMessagesByPhoneNumber", {
                organizationiD: currentUser.organization,
                phoneNumber: phoneNumber,
            });
            const messages = response.data || [];
            setChatMessages(messages);
        } catch (error) {
            console.log('Error fetching messages:', error);
        }
    }, [currentUser.organization]);

    useEffect(() => {
        if (contactphonenumber) {
            fetchChatMessages(contactphonenumber);
            checkConversationStatus(currentUser.organization, contactphonenumber);
        }
    }, [contactphonenumber, fetchChatMessages]);

    useEffect(() => {
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'messages') {
                fetchChatMessages(contactphonenumber);
            }
        };

        if (webSocket) {
            webSocket.addEventListener('message', handleMessage);
        }

        return () => {
            if (webSocket) {
                webSocket.removeEventListener('message', handleMessage);
            }
        };
    }, [contactphonenumber, webSocket, fetchChatMessages]);

    const checkConversationStatus = async (organization, phoneNumber) => {
        try {
            const liveResponse = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/IsConversationLiveByPhoneNumber", { organization, phoneNumber });
            setIsConversationLiveByPhoneNumber(liveResponse.data);
            

            const replyResponse = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/isRecipientReplyLast24Hours", { organization, phoneNumber });
            setisRecipientReplyLast24Hours(replyResponse.data);

            console.log('isRecipientReplyLast24Hours', isRecipientReplyLast24Hours);
            console.log('IsConversationLiveByPhoneNumber', IsConversationLiveByPhoneNumber);
        } catch (error) {
            console.error("Error checking conversation status", error);
        }
    };

    const send = async (e) => {
        e.preventDefault();
        if (contactphonenumber && message.trim() !== '') {
            const messageText = message.replace(/(?:\r\n|\r|\n)/g, '\\n');
            const payload = {
                text: messageText,
                from: currentUser.wabaNumber,
                to: contactphonenumber,
                senderEmail: currentUser.email,
                receiverEmail: contactphonenumber,
                timeStamp: '',
                sentByName: currentUser.fullname,
                sentById: currentUser.userId,
                organization: currentUser.organization
            };

            try {
                await axios.post("https://gambot.azurewebsites.net/api/Webhooks/CreateOutboundMessage", payload);
                setMessage('');
                setCurrentChatRecipient(contactphonenumber);
                fetchChatMessages(contactphonenumber);
            } catch (error) {
                console.log('Error sending message:', error);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.altKey) {
            event.preventDefault();
            setMessage(message + '\n');
        } else if (event.key === 'Enter' && !event.altKey) {
            event.preventDefault();
            send(event);
        }
    };

    return (
        <div className='chat-container'>
            <div className='chat-container-header'>
                <div className='chat-user-info'>
                    <div className='chat-user-img'>
                        {/* <img src={photoURL} alt='./question-mark.png' /> */}
                    </div>
                    <p>{selectedContact?.name + " " + contactphonenumber || ""}</p>
                </div>
                <div className='chat-container-header-btn'>
                    <MoreVertIcon />
                </div>
            </div>

            <div className='chat-display-container' ref={chatBox}>
                {chatMessages && chatMessages.length > 0 ? (
                    chatMessages.map((message) => (
                        <ChatMessage
                            key={message.id}
                            message={message.text}
                            time={message.timeStamp}
                            sender={contactphonenumber}
                            from={message.from}
                            id={message}
                            orgazanizationNumber={currentUser.wabaNumber}
                            userName={message.sentByName}
                            messageType={message.type}
                        />
                    ))
                ) : (
                    <p>No messages</p>
                )}
            </div>

            <div className='chat-input'>
                {IsConversationLiveByPhoneNumber && !isRecipientReplyLast24Hours ? (
                    <p>Wait for user reply</p>
                ) : isRecipientReplyLast24Hours && IsConversationLiveByPhoneNumber ? (
                    <>
                        {openEmojiBox && <Picker onEmojiClick={(event, emojiObject) => setMessage(message + emojiObject.emoji)} />}
                        <div className='chat-input-btn'>
                            <InsertEmoticon onClick={() => setOpenEmojiBox(!openEmojiBox)} />
                            <AttachFileIcon />
                        </div>
                        <form onSubmit={send}>
                            <textarea
                                placeholder='Type a message'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={handleKeyPress}
                                rows="3"
                            />
                        </form>
                        <div className='chat-input-send-btn' onClick={send}>
                            <SendIcon />
                        </div>
                    </>
                ) : (
                    <button onClick={() => setShowPopup(true)}>Start New Conversation</button>
                )}
            </div>

            {showPopup && (
                <div className='popup'>
                    <div className='popup-inner'>
                        <StartConversationByTemplate 
                            currentUser={currentUser}
                            contactPhoneNumber={contactphonenumber} 
                            onClose={() => setShowPopup(false)} 
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(ChatContainer);
