import React, { useEffect, useState, useCallback, useRef } from 'react';
import TollIcon from '@mui/icons-material/Toll';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import './Sidebar.css';
import UserProfile from './UserProfile';
import NewContactForm from './NewContactForm.js';
import axios from 'axios';

function Sidebar({ currentUser, signOut, currentChatRecipient, webSocket, onContactsUpdate, onContactClick }) {
    const [searchInput, setSearchInput] = useState('');
    const [contacts, setContacts] = useState([]);
    const [showNewContactForm, setShowNewContactForm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const hasFetchedContacts = useRef(false);

    const closeForm = () => {
        setShowNewContactForm(false);
    };

    const fetchContacts = useCallback(async () => {
        console.log('Fetching contacts');
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg", {
                organizationiD: currentUser.organization
            });
            const fetchedContacts = response.data || [];
            fetchedContacts.sort((a, b) => new Date(b.time) - new Date(a.time));
            setContacts(fetchedContacts);
            onContactsUpdate(fetchedContacts);
            hasFetchedContacts.current = true;
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    }, [currentUser.organization, onContactsUpdate]);

    useEffect(() => {
        if (!hasFetchedContacts.current) {
            fetchContacts();
        }
    }, [fetchContacts]);

    useEffect(() => {
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            console.log('WebSocket message:', message);
            if (message.type === 'contacts') {
                fetchContacts();
            }
        };

        if (webSocket) {
            webSocket.addEventListener('message', handleMessage);
        }

        return () => {
            if (webSocket) {
                webSocket.removeEventListener('message', handleMessage);
            }
        };
    }, [webSocket, fetchContacts]);

    const filteredContacts = contacts.filter(contact => 
        (contact.name && contact.name.toLowerCase().includes(searchInput.toLowerCase())) ||
        (contact.phoneNumber && contact.phoneNumber.toLowerCase().includes(searchInput.toLowerCase()))
    );

    const handleAddContact = newContact => {
        console.log('Adding new contact:', newContact);
        setShowNewContactForm(false);
    };

    const companyLogo = './GamBot_Logo.png';
    const photoURL = './question-mark.png';

    return (
        <div className='sidebar'>
            <div className='sidebar-header'>
                <div className='sidebar-header-img' onClick={signOut}>
                    <img src={companyLogo} alt='' />
                </div>
                <div className='sidebar-header-btn'>
                    <TollIcon />
                    <InsertCommentIcon onClick={() => setShowNewContactForm(true)} />
                    <MoreVertIcon />
                </div>
            </div>
            <div className='sidebar-search'>
                <div className='sidebar-search-input'>
                    <SearchIcon />
                    <input
                        type='text'
                        placeholder='Search by name or phone...'
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                    />
                </div>
            </div>

            <div className='sidebar-chat-list'>
                {filteredContacts.length > 0 ? (
                    filteredContacts.map(contact => (
                        <UserProfile
                            key={contact.id}
                            name={contact.name || 'Unknown Name'}
                            photoURL={photoURL}
                            lastMessage={contact.lastMessage || "No message available"}
                            email={contact.email || 'default@email.com'}
                            time={contact.time}
                            contact={contact}
                            onClick={() => onContactClick(contact)}
                        />
                    ))
                ) : (
                    <p>No contacts found.</p>
                )}
            </div>

            {showNewContactForm && (
                <div className={`new-contact-form-modal ${showForm ? 'show' : ''}`}>
                    <div className="new-contact-form-modal-content">
                        <span className="new-contact-form-close-btn" onClick={closeForm}>
                            &times;
                        </span>
                        <NewContactForm
                            onAddContact={handleAddContact}
                            currentUser={currentUser}
                            showForm={showForm}
                            toggleForm={() => setShowForm(!showForm)}
                            closeForm={closeForm}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default React.memo(Sidebar);
