import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import './Botomation.css';
import axios from 'axios';
import ConfirmationDialog from '../ConfirmationDialog';
import { useHistory } from 'react-router-dom';
const Botomation = ({currentUser}) =>{
    console.log(currentUser.organization);

    const navigate = useNavigate();

    const[botomations , setBotomation] = useState([]);
    const[selectedBotomation , setSelectedBotomation] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); 
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllBotomations', {
            organization: currentUser.organization
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          setBotomation(response.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }, [currentUser.organization]);
    const handleDeleteClick = (botomation) => {
        console.log(botomation);
        setSelectedBotomation(botomation);
        setOpenDialog(true);
      };
      const handleNewBotomation = ()=>{
        const thisIntegrationQueryString = new URLSearchParams(
            {
                "organization": currentUser.organization,
                "userEmail" : currentUser.Email,
                "mode" : "Create"
            }
        )
        navigate(`/NewBotomation/?${thisIntegrationQueryString}`);
    
      }
      const handleEditClick = (integration) => {
        const thisIntegrationQueryString = new URLSearchParams({
          "organization": currentUser.organization,
          ...integration
        })
        navigate(`/UpdateBotomation/?${thisIntegrationQueryString}`);
      };
    
      
      const handleConfirmDelete = () => {
        console.log(selectedBotomation);
        // Call your delete integration API here with selectedIntegration.id
        var payload = {
          "organization": currentUser.organization,
          "BotomationId": selectedBotomation.Id

        }
        console.log(payload);
        axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteBotomation", payload)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          })
        setOpenDialog(false);
      };
    
      return (
        <div className='botomation-list-container'>
          <h1 className='botomation-list-header'>Botomations</h1>
          <div className='botomation-list-table-container'>
            <table className='botomation-list-table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Trigger</th>
                  <th>Template</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {botomations.map((botomation) => (
                  <tr key={botomation.id}>
                    <td>{botomation.Name}</td>
                    <td>{botomation.Status}</td>
                    <td>{botomation.trigger}</td>
                    <td>{botomation.Template}</td>
                    <td>
                      <button className="botomation-edit-button" onClick={() => handleEditClick(botomation)}>Edit</button>
                      <button className="botomation-delete-button" onClick={() => handleDeleteClick(botomation)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ConfirmationDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            onConfirm={handleConfirmDelete}
          />
          <div className='botomation-action-buttons'>
            <button className="add-new-botomation-button" onClick={() => handleNewBotomation()}>Add New Botomation</button>
          </div>
        </div>
      );
      
};

export default Botomation;
