import MenuIcon from '@mui/icons-material/Menu'; // Import for the menu icon
import CloseIcon from '@mui/icons-material/Close'; // Import for the close icon
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import { MenuDATA } from './MenuData'; // Import the MenuDATA

const Menu = () => {
    const [isExpanded, setIsExpanded] = useState(true);
  
    const toggleMenu = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <div className={`Menu ${isExpanded ? '' : 'collapsed'}`}>
        <div className='Menu-items'>
          <ul>
            {MenuDATA.map((item, index) => (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <button onClick={toggleMenu} className="toggle-menu-btn">
          {isExpanded ? <CloseIcon /> : <MenuIcon />}
        </button>
      </div>
    );
  }
  
  export default Menu;
  