import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HeaderTypeSelector from './HeaderTypeSelector';
import MediaOptions from './MediaOptions';
import VariableInput from './VariableInput';
import MessagePreview from './MessagePreview';
import ButtonOptions from './ButtonOptions';
import FooterText from './FooterText';
import BodyText from './BodyText';
import axios from 'axios';
const TemplateUpdate = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const templateId = useMemo(() => searchParams.has('Id') ? searchParams.get('Id') : null, [searchParams]);
    const templateName = useMemo(() => searchParams.has('name') ? searchParams.get('name') : null, [searchParams]);
    const status = useMemo(() => searchParams.has('status') ? searchParams.get('status') : null, [searchParams]);
    const category = useMemo(() => searchParams.has('category') ? searchParams.get('category') : null, [searchParams]);
    const language = useMemo(() => searchParams.has('language') ? searchParams.get('language') : null, [searchParams]);
    const components = useMemo(() => searchParams.has('components') ? JSON.parse(searchParams.get('components')) : null, [searchParams]);
    const error = useMemo(() => searchParams.has('error') ? searchParams.get('error') : null, [searchParams]);
    const organization = useMemo(() => searchParams.has('organization') ? searchParams.get('organization') : 'No organization specified', [searchParams]);
    const navigate = useNavigate();
    const [headerType, setHeaderType] = useState('media');
    const [mediaType, setMediaType] = useState('none');
    const [mediaContent, setMediaContent] = useState(null);
    const [mediaUrl, setMediaUrl] = useState(null);
    const [variables, setVariables] = useState([]);
    const [footerText, setFooterText] = useState('');
    const [bodyContent, setBodyContent] = useState('');
    const [variableSamples, setVariableSamples] = useState({});
    const [buttons, setButtons] = useState([]);
    const [headerTxt, setHeaderTxt] = useState('');
    const [mediaFile, setMediaFile] = useState('');
    const [mediaPublicUrl, setMediaPublicUrl] = useState('');
    const [flag, setFlag] = useState(false);
    const [changed, setChanged] = useState(false);
    const [gmbtMedia , setGmbtMedia] = useState({});
    
    const handleAddButton = (button) => {
        setButtons([...buttons, button]);
    };

    const handleRemoveButton = (index) => {
        const updatedButtons = [...buttons];
        updatedButtons.splice(index, 1);
        setButtons(updatedButtons);
    };

    const handleBackButtonClick = () => {
        navigate('/Templates');
    };

    const handleBodyContentChange = (newBodyContent) => {
        setBodyContent(newBodyContent);
    };

    const handleHeaderTypeChange = (type) => {
        setHeaderType(type);
    };

    const handleMediaTypeChange = (type, fileType, fileContent, mediaUrl, mediaFile) => {
        setMediaType(type); // Updated from fileType to type
        setMediaContent(fileContent);
        setMediaUrl(mediaUrl);
        setMediaFile(mediaFile);
    };

    const handleAddVariable = (variable) => {
        setVariables([...variables, variable]);
    };

    const handleRemoveVariable = (index) => {
        const updatedVariables = [...variables];
        updatedVariables.splice(index, 1);
        setVariables(updatedVariables);
    };

    const handleFooterTextChange = (text) => {
        setFooterText(text);
    };

    const handleVariableSampleChange = (variable, sample) => {
        setVariableSamples({ ...variableSamples, [variable]: sample });
    };

    const handleHeaderTxt = (txt) => {
        setHeaderTxt(txt);
    };

    const handleAddVariableToBody = (updatedContent) => {
        setBodyContent(updatedContent);
    };
     
    const getGmbtMediaUrlByTemplateId = async (templateId) => {
        try {
            const response = await axios.post('https://gambot.azurewebsites.net/api/webhooks/GetMediaByTemplateId', {
                organization: organization,
                templateId: templateId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setGmbtMedia(response.data);
            const mediaUrl = response.data.mediaUrl;
            const encodedMediaUrl = encodeURI(mediaUrl); // Encode the URL
            setMediaUrl(encodedMediaUrl);
            console.log(encodedMediaUrl);

        } catch (error) {
            console.error('Error fetching media URL:', error);
        }
    };

    useEffect(() => {
        if (components) {
            const headerComponent = components.find(component => component.type === 'HEADER');
            if (headerComponent) {
                switch (headerComponent.format) {
                    case 'IMAGE':
                        setHeaderType('media');
                        setMediaUrl(getGmbtMediaUrlByTemplateId(templateId));
                        setMediaType(headerComponent.format.toLowerCase()); // Set media type
                    case 'VIDEO':
                        setHeaderType('media');
                        setMediaType(headerComponent.format.toLowerCase()); // Set media type
                    case 'DOCUMENT':
                        setHeaderType('media');
                        setMediaUrl(getGmbtMediaUrlByTemplateId(templateId));
                        setMediaType(headerComponent.format.toLowerCase()); // Set media type
                    break;
                    case 'TEXT':
                        setHeaderType('text');
                        setHeaderTxt(headerComponent.text);
                    break;
                    default:
                        setHeaderType('media');
                    break;
                }
            }

            const bodyComponent = components.find(component => component.type === 'BODY');
            if (bodyComponent) {
                const bodyText = bodyComponent.text || '';
                const bodyVariables = bodyComponent.example?.body_text?.map(variable => [...variable]) || [];
                setBodyContent(bodyText);
                setVariables(bodyVariables.length >0 ? bodyVariables[0] : bodyVariables);
                const variableSamples = {};
                bodyComponent.example?.body_text?.forEach((variable, index) => {
                    variableSamples[variable[0]] = variable[1] || '';
                });
                setVariableSamples(variableSamples);
            } else {
                console.error("No body component found");
            }

            const footerComponent = components.find(component => component.type === 'FOOTER');
            if (footerComponent && footerComponent.text) {
                setFooterText(footerComponent.text);
            }
            const buttonsComponent = components.find(component => component.type === 'BUTTONS');
            if (buttonsComponent) {
                const buttonsData = buttonsComponent.buttons.map(button => ({
                    label: button.text,
                    value: button.text
                }));
                setButtons(buttonsData);
            }
        }
    }, [templateId]);

    const buildHeader = (mediaPublicUrl) => {
        let headerComponent = null;
        switch (headerType) {
            case 'media':
                headerComponent = {
                    "type": "HEADER",
                    "format": mediaType.toUpperCase(), // Use the mediaType state to set the format dynamically
                    "example": {
                        "header_handle": [
                            mediaPublicUrl
                        ]
                    }
                };
                break;
            case 'location':
                headerComponent = {
                    "type": "HEADER",
                    "format": "LOCATION"
                };
                break;
            case 'text':
                headerComponent = {
                    "type": "HEADER",
                    "format": "TEXT",
                    "text": headerTxt,
                    "example": {
                        "header_text": [
                            headerTxt
                        ]
                    }
                };
                break;
            default:
                break;
        }

        return headerComponent;
    };

    const handleConfirmTemplate = async () => {
        const template = {
            name: templateName.toLowerCase(),
            language: language,
            category: category,
            components: []
        };

        if (headerType === 'text' && headerTxt !== '') {
            template.components.push({
                type: "HEADER",
                format: "TEXT",
                text: headerTxt,
                example: {
                    header_text: headerTxt
                }
            });
        } else if (headerType === 'media' && mediaContent !== null) {
            const formData = new FormData();
            formData.append('File', mediaFile);
            formData.append('Org', organization);
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/CreateWabaMedia', formData, {
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                });
                const mediaId = response.data.mediaId;
                setMediaPublicUrl(response.data.mediaUrl);
                const headerComponent = buildHeader(response.data.mediaUrl);
                if (headerComponent) {
                    template.components.push(headerComponent);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }

        if (bodyContent !== '' && variables.length > 0) {
            let text = bodyContent;
            variables.forEach((variable, index) => {
                let tempI = index + 1;
                let regex = new RegExp(`{{\\s*${variables[index].replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\s*}}`, 'g');
                text = text.replace(regex, `{{${tempI}}}`);
            });
            template.components.push({
                type: "BODY",
                text: text,
                example: {
                    body_text: variables.map(variable => [variable])
                }
            });
        }

        if (buttons.length > 0) {
            const buttonsData = buttons.map(button => ({
                type: "QUICK_REPLY",
                text: button.label
            }));

            template.components.push({
                type: "BUTTONS",
                buttons: buttonsData
            });
        }

        if (footerText !== '') {
            template.components.push({
                type: "FOOTER",
                text: footerText
            });
        }
        let templateJson = JSON.stringify(template, null, 2);
        const jsonDataToCreateTemplate = {
            organization: organization,
            templateId : templateId,
            template: templateJson
        };
        console.log(jsonDataToCreateTemplate)
        axios.post("https://gambot.azurewebsites.net/api/Webhooks/UpdateTemplate", jsonDataToCreateTemplate)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    return (
        <div className="template-generator-container">
            <div className="left-side">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={handleBackButtonClick}
                        style={{ color: 'var(--LogoColor)' }}
                        aria-label="back"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <HeaderTypeSelector selectedType={headerType} onChange={handleHeaderTypeChange} />
                </div>
                {headerType === 'media' && (
                    <MediaOptions selectedType={mediaType} onChange={handleMediaTypeChange} mediaurl={mediaUrl}/>
                )}
                {headerType === "text" && (
                    <input
                        type="text"
                        placeholder='Set Text For Header'
                        value={headerTxt}
                        onChange={(e) => handleHeaderTxt(e.target.value)}
                        className="variable-input-header-txt"
                    />
                )}
                <BodyText
                    bodyText={bodyContent}
                    variables={variables}
                    onAddSample={handleBodyContentChange}
                    onVariableSampleChange={handleVariableSampleChange}
                    isChanged={!changed}
                />
                <VariableInput
                    variables={variables}
                    onAddVariable={handleAddVariable}
                    onRemoveVariable={handleRemoveVariable}
                    onAddVariableToBody={handleAddVariableToBody}
                    bodyText={bodyContent}
                />
                <FooterText text={footerText} onChange={handleFooterTextChange} />
                <ButtonOptions buttons={buttons} onAddButton={handleAddButton} onRemoveButton={handleRemoveButton} />
            </div>
            <div className="right-side">
                <button onClick={handleConfirmTemplate}>
                    Confirm Template
                </button>
                <MessagePreview
                    headerType={headerType}
                    mediaType={mediaType}
                    mediaContent={mediaContent}
                    mediaUrl={mediaUrl}
                    variables={variables}
                    footerText={footerText}
                    variableSamples={variableSamples}
                    bodyContent={bodyContent}
                    buttons={buttons}
                    headerTxt={headerTxt}
                />
            </div>
        </div>
    );
};

export default React.memo(TemplateUpdate);
