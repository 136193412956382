import React from 'react';
import './FooterText.css';
const  FooterText = ({ text, onChange }) => {
  return (
    <div className='footer-text-container'>
      <label className='footer-text-label'>Footer Text:</label>
      <p className='footer-text-p'>Add a short line of text to the bottom of your message template</p>
      <input className='Footer-Text' type="text" value={text} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export default FooterText;
