import React, { useState } from 'react';
import './NewFormFlows.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Select from 'react-select';

const NewFormFlow = ({ currentUser }) => {
  const [flowName, setFlowName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [endpoint, setEndpoint] = useState('');

  const categories = [
    'SIGN_UP', 'SIGN_IN', 'APPOINTMENT_BOOKING', 'LEAD_GENERATION',
    'CONTACT_US', 'CUSTOMER_SUPPORT', 'SURVEY', 'OTHER'
  ];

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleFlowNameChange = (event) => {
    setFlowName(event.target.value);
  };

  const handleEndpointChange = (event) => {
    setEndpoint(event.target.value);
  };

  const mode = "Create";
  const queryParams = new URLSearchParams({
    mode,
    flowName,
    selectedCategory: selectedCategory.value,
    endpoint,
    organization: currentUser.organization
  }).toString();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted:', { flowName, selectedCategory, endpoint });
  };

  return (
    <div className="new-form-flow-container">
      <h1>New Form Flow</h1>
      <form className='new-form-flow-form' onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter form flow name..."
          value={flowName}
          onChange={handleFlowNameChange}
          className="form-flow-name-input"
        />
        <div className="new-form-flow-form-category-selection">
          <Select
            options={categories.map(category => ({ value: category, label: category }))}
            onChange={handleCategoryChange}
            placeholder="Select a category"
            value={selectedCategory}
          />
        </div>
        <input
          type="text"
          placeholder="Enter endpoint URL..."
          value={endpoint}
          onChange={handleEndpointChange}
          className="form-flow-endpoint-input"
        />
        <br />
        <div className="new-form-flow-action-buttons">
          <Link to="/FormFlows">
            <button className="new-form-flow-action-buttons-cancel" type="button">
              Cancel
            </button>
          </Link>
          <Link
            to={{
              pathname: "/FormFlowsGenerator",
              search: `?${queryParams}`,
            }}
          >
            <button className="new-form-flow-action-buttons-continue" type="submit">
              Continue
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default NewFormFlow;
