import React, { useState } from 'react';
import './NewMessageTemplate.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LookUp from '../Lookup'; // Import the LookUp component
import Select, { components } from 'react-select';

const NewMessageTemplate = (currentUser) => {
  console.log(currentUser);
  const [templateName, setTemplateName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const categories = [
    { name: 'Marketing', icon: '📢' },
    { name: 'Utility', icon: '🛠️' },
    { name: 'Auth', icon: '🔒' }
  ];

  // Define your list of languages
  const languages = [
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'Bulgarian',
    'Catalan',
    'Cebuano',
    'Chichewa',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Corsican',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Filipino',
    'Finnish',
    'French',
    'Frisian',
    'Galician',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hmong',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Korean',
    'Kurdish (Kurmanji)',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Myanmar (Burmese)',
    'Nepali',
    'Norwegian',
    'Odia (Oriya)',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Samoan',
    'Scots Gaelic',
    'Serbian',
    'Sesotho',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Spanish',
    'Sundanese',
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zulu',
    // Add more languages as needed
  ];


  // Handlers for input changes, form submission, etc.
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };
  console.log(currentUser.currentUser.organization);
  const mode = "Create";
  const queryParams = new URLSearchParams({
    mode,
    templateName,
    selectedCategory,
    selectedLanguage: JSON.stringify(selectedLanguage),
    organization : currentUser.currentUser.organization
  }).toString();
  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit logic here
    console.log('Submitted:', { templateName, selectedCategory, selectedLanguage });
  };

  // Category selection, name input, language selection, and buttons
  return (
    <div className="new-message-template-container">
      <h1>New Message Template</h1>
      <form className='new-message-template-form' onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter message template name..."
          value={templateName}
          onChange={handleTemplateNameChange}
          className="template-name-input"
        />
        <div className="category-selection">
          {categories.map((category) => (
            <button
              
            key={category.name}
              className={`category-button ${selectedCategory === category.name ? 'active' : ''
                }`}
              onClick={() => setSelectedCategory(category.name)}
            >
              <span className="category-icon">{category.icon}</span>
              {category.name}
            </button>
          ))}
        </div>

        <div className="language-selection">
          {/* Use the LookUp component for language selection */}
          <LookUp
            options={languages.map((language) => ({ value: language, label: language }))}
            onChange={handleLanguageChange}
            placeholder="Select a language..."
            value={selectedLanguage} // Pass the selected value
          />
        </div>
        <br></br>
        <div className="new-message-template-action-buttons">
          <Link to="/Templates">
            <button className="new-message-template-action-buttons-cancel" type="button" onClick={() => { }}>
              Cancel
            </button>
          </Link>
          <Link
            to={{
              pathname: "/New-Template",
              search: `?${queryParams}`,
            }}
          >
            <button className="new-message-template-action-buttons-continue" type="submit">
              Continue
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default NewMessageTemplate;
