import React, { useState, useMemo , useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './IntegrationsForm.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios'; // Import Axios

const IntegrationsForm = ({ onSubmit, integration }) => {
    const [successMessage, setSuccessMessage] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const organization = useMemo(() => searchParams.has('organization') ? searchParams.get('organization') : null, [searchParams]);
    const userEmail = useMemo(() => searchParams.has('userEmail') ? searchParams.get('userEmail') : null, [searchParams]);
    const mode = useMemo(() => searchParams.has('mode') ? searchParams.get('mode') : null, [searchParams]);

    console.log(organization);
    const options = { timeZone: 'Asia/Jerusalem' };
    const createdOn = new Date().toLocaleString('en-US', options);
    const updatedOn = new Date().toLocaleString('en-US', options);

    console.log(searchParams.get('name')); // Output: Dynamics crm
    console.log(searchParams.get('IntegrationId')); // Output: Dynamics crm
    const isEdit = searchParams.has('IntegrationId') ? true : false;
    const [integrationData, setIntegrationData] = useState(() => {
        if (searchParams.has('IntegrationId')) {
            console.log("in");
            return {
                integrationId: searchParams.get('IntegrationId') || '',
                name: searchParams.get('name') || '',
                type: searchParams.get('type') || '',
                database: searchParams.get('database') || '',
                status: searchParams.get('status') || '',
                lastSynced: searchParams.get('lastSynced') || '',
                clientId: searchParams.get('clientId') || '',
                clientSecret: searchParams.get('clientSecret') || '',
                connectionString: searchParams.get('connectionString') || '',
                updatedDate: searchParams.get('updatedDate') || '',
                description: searchParams.get('description') || '',
                endpoint: searchParams.get('endpoint') || '',
                token: searchParams.get('token') || '',
                isActive: searchParams.get('isActive') || '',
                environment: searchParams.get('environment') || '',
                createdOn: searchParams.get('createdOn') || '',
                createdBy: searchParams.get('createdBy') || '',
                updatedOn: searchParams.get('updatedOn') || '',
                updatedBy: searchParams.get('updatedBy') || '',
                error: searchParams.get('error') || '',
            };
        } else {
            return integration || {
                name: '',
                type: '',
                database: '',
                status: '',
                lastSynced: '',
                clientId: '',
                clientSecret: '',
                connectionString: '',
                createdOn: createdOn,
                updatedOn: updatedOn,
                createdBy: userEmail
            };
        }
    });

    console.log(integrationData);

    // const [integrationData, setIntegrationData] = useState(integration || {
    //     name: '',
    //     type: '',
    //     database: '',
    //     status: '',
    //     lastSynced: '',
    //     clientId: '',
    //     clientSecret: '',
    //     connectionString: '',
    //     createdOn: createdOn,
    //     updatedOn: updatedOn,
    //     createdBy: userEmail
    // });


    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        navigate('/Integrations'); // Change the path as needed
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIntegrationData({ ...integrationData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log({
            organization: organization,
            integrationData: integrationData
        })
        if (isEdit !== true) {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/CreateIntegration', {
                    organization: organization,
                    integrationData: integrationData
                });
                setSuccessMessage('Integration created successfully!');

                console.log(response.data); // Log the response from the backend
                // Handle any success actions here
            } catch (error) {
                console.error(error); // Log any errors
                // Handle any error actions here
            }
        }
        else {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/UpdateIntegration', {
                    organization: organization,
                    integrationData: integrationData

                });
                setSuccessMessage('Integration Update successfully!');

                console.log(response.data); // Log the response from the backend
                // Handle any success actions here
            } catch (error) {
                console.error(error); // Log any errors
                // Handle any error actions here
            }
        }

    };

    const handlePing = () => {
        // Handle ping functionality
    };
    useEffect(() => {
        if (successMessage) {
          alert(successMessage);
          navigate('/Integrations'); // Redirect to integrations page
        }
      }, [successMessage]);
    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="integration-form">
                <div className="integrations-form-header">
                    <IconButton onClick={handleBackButtonClick} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <h2>{isEdit === true ? "Update" : "Create"} Integration Form</h2>
                </div>
                <div className="integrations-form-row">
                    <label>
                        Integration Name:
                        <input type="text" name="name" value={integrationData.name} onChange={handleChange} required />
                    </label>
                </div>
                <div className="integrations-form-row">
                    <label>
                        Type:
                        <select name="type" value={integrationData.type} onChange={handleChange} required>
                            {[
                                "API Keys",
                                "Bearer Token",
                                "Client Certificates",
                                "Custom Tokens",
                                "Digest Access Authentication",
                                "HTTP Basic Authentication",
                                "JWT (JSON Web Tokens)",
                                "OAuth",
                                "OAuth2 Access Tokens"
                            ].sort().map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className="integrations-form-row">
                    <label>
                        Database
                        <input type="text" name="database" value={integrationData.database} onChange={handleChange} required />
                    </label>
                </div>
                <div className="integrations-form-row">
                    <label>
                        Status:
                        <input type="text" name="status" value={integrationData.status} onChange={handleChange} required />
                    </label>
                </div>
                {integrationData.type === 'OAuth2 Access Tokens' && (
                    <>
                        <div className="integrations-form-row">
                            <label>
                                Client ID:
                                <input type="text" name="clientId" value={integrationData.clientId} onChange={handleChange} required />
                            </label>
                        </div>
                        <div className="integrations-form-row">
                            <label>
                                Client Secret:
                                <input type="password" name="clientSecret" value={integrationData.clientSecret} onChange={handleChange} required />
                            </label>
                        </div>
                        <div className="integrations-form-row">
                            <label>
                                Connection String:
                                <input type="password" name="connectionString" value={integrationData.connectionString} onChange={handleChange} required />
                            </label>
                        </div>
                    </>
                )}
                <div className="integrations-form-row">
                    <button className='integrations-form-ping' type="button" onClick={handlePing}>Ping</button>
                    <button className='integrations-form-submit' type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default IntegrationsForm;
