import React, { useState } from 'react';
import './UpdateKeysForm.css';

function UpdateKeysForm({ onClose, onSave, contacts, showForm }) {
  const [operation, setOperation] = useState('add'); // 'add' or 'delete'
  const [newKey, setNewKey] = useState('');
  const [keysToAdd, setKeysToAdd] = useState([]);
  const [keyToDelete, setKeyToDelete] = useState('');

  const allKeys = Array.from(new Set(contacts.flatMap(contact => contact.keys)));

  const handleSave = () => {
    if (operation === 'add') {
      onSave(operation, keysToAdd);
    } else if (operation === 'delete') {
      onSave(operation, keyToDelete);
    }
    onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newKey.trim() !== '') {
      e.preventDefault();
      setKeysToAdd(prevKeys => [...prevKeys, newKey.trim()]);
      setNewKey('');
    }
  };

  const handleDeleteKey = (keyToDelete) => {
    setKeysToAdd(keysToAdd.filter(key => key !== keyToDelete));
  };

  return (
    <div className={`update-keys-form-modal ${showForm ? 'show' : ''}`}>
      <div className="update-keys-form-modal-content">
        <button className="update-keys-form-close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>Update Keys for Selected Contacts</h2>

        <div className="form-group">
          <label>
            <input
              type="radio"
              value="add"
              checked={operation === 'add'}
              onChange={() => setOperation('add')}
            />
            Add Key
          </label>
          <label>
            <input
              type="radio"
              value="delete"
              checked={operation === 'delete'}
              onChange={() => setOperation('delete')}
            />
            Delete Key
          </label>
        </div>

        {operation === 'add' ? (
          <div className="form-group">
            <label htmlFor="newKey">Enter Key to Add:</label>
            <input
              type="text"
              id="newKey"
              value={newKey}
              onChange={(e) => setNewKey(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className="keys-list">
              {keysToAdd.map((key, index) => (
                <span key={index} className="key-item">
                  {key}
                  <button onClick={() => handleDeleteKey(key)}>x</button>
                </span>
              ))}
            </div>
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="keyToDelete">Select Key to Delete:</label>
            <select
              id="keyToDelete"
              value={keyToDelete}
              onChange={(e) => setKeyToDelete(e.target.value)}
            >
              <option value="" disabled>Select a key</option>
              {allKeys.map((key, index) => (
                <option key={index} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        )}

        <button className="update-keys-form-save" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default UpdateKeysForm;
