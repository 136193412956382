import React, { useEffect } from 'react';
import './FacebookLogin.css';

const FacebookLogin = () => {
  useEffect(() => {
    // Load the Facebook SDK
    const loadFbSdk = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '655177883181597',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v19.0',
        });
      };

      // Load the SDK asynchronously
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    };

    loadFbSdk();
  }, []);

  const launchWhatsAppSignup = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          // Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: '432095546293940', // configuration ID obtained in the previous step goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    );
  };

  return (
    <button className="fb-login-button" onClick={launchWhatsAppSignup}>
      Login with Facebook
    </button>
  );
};

export default FacebookLogin;
