import React, { useState, useEffect } from 'react';
import ConditionGroup from './ConditionGroup';
import './ConditionAction.css';
import OutboundMessageAction from './OutboundMessageAction';
import SendHttpRequestAction from './SendHttpRequestAction';
import IncomingMessageTrigger from './IncomingMessageTrigger';

const ConditionAction = ({ onChange, steps, currentUser, conditionId, initialConfig }) => {
  const [conditionGroups, setConditionGroups] = useState([{ logicOperator: 'AND', conditions: [{ field: '', operator: 'equals', value: '' }] }]);
  const [groupRelations, setGroupRelations] = useState([]);
  const [yesActions, setYesActions] = useState([]);
  const [noActions, setNoActions] = useState([]);
  const [isGeneral, setIsGeneral] = useState(false);
  console.log(initialConfig);


 

  // Initialize state from initialConfig if provided
  useEffect(() => {
    if (initialConfig) {
      setConditionGroups(initialConfig.conditionGroups || [{ logicOperator: 'AND', conditions: [{ field: 'ResponseLastSentTemplateMessageButtonClicked', operator: 'equals', value: '' }] }]);
      setGroupRelations(initialConfig.groupRelations || []);
      setYesActions(initialConfig.yesActions || []);
      setNoActions(initialConfig.noActions || []);
      setIsGeneral(initialConfig.isGeneral || false);
    }
  }, [initialConfig]);

  useEffect(() => {
    onChange({ conditionId, conditionGroups, groupRelations, yesActions, noActions, isGeneral });
  }, [conditionGroups, groupRelations, yesActions, noActions, isGeneral]);
  const normalizeStep = (step) => ({
    ...step,
    action: step.Action || step.action,
    config: step.Config || step.config,
    type: step.Type || step.type,
  });
  
  const getButtonLabelsAndTemplateIds = () => {
    const buttonLabels = [];
    const templateIds = [];
  
    const processSteps = (stepList) => {
      stepList.forEach((step) => {
        // Normalize the step to ensure consistent property access
        step = normalizeStep(step);
        console.log(step);
        if (step.type === 'action' && step.action === 'SendMessage' && step.config?.templateConfig?.template?.components) {
          const templateName = step.config.templateConfig.template.name || 'Unnamed Template';
          const templateId = step.config.templateConfig.template.Id;
  
          // Iterate through components to find BUTTONS and extract button texts and template IDs
          step.config.templateConfig.template.components.forEach((component) => {
            if (component.type === 'BUTTONS' && component.buttons) {
              component.buttons.forEach((button) => {
                if (button.text) {
                  buttonLabels.push(`${templateName} + ${button.text}`);
                  templateIds.push(templateId);
                }
              });
            }
          });
        }
  
        // Recursively process nested steps for conditions
        if (step.type === 'action' && step.action === 'Condition') {
          processSteps(step.config?.yesActions || []);
          processSteps(step.config?.noActions || []);
        }
      });
    };
  
    processSteps(steps);
  
    return { buttonLabels, templateIds };
  };
  console.log(steps);
  const { buttonLabels, templateIds } = getButtonLabelsAndTemplateIds();
  console.log(buttonLabels);
  const handleGroupChange = (index, updatedGroup) => {
    const newGroups = [...conditionGroups];
    newGroups[index] = updatedGroup;
    setConditionGroups(newGroups);
  };

  const addGroup = () => {
    setConditionGroups([...conditionGroups, { logicOperator: 'AND', conditions: [{ field: '', operator: 'equals', value: '' }] }]);
    setGroupRelations([...groupRelations, 'AND']);
  };

  const handleDeleteGroup = (index) => {
    const newGroups = conditionGroups.filter((_, i) => i !== index);
    const newRelations = groupRelations.filter((_, i) => i !== index - 1);
    setConditionGroups(newGroups);
    setGroupRelations(newRelations);
  };

  const handleAddCondition = (groupIndex) => {
    const newGroups = [...conditionGroups];
    newGroups[groupIndex].conditions.push({ field: '', operator: 'equals', value: '' });
    setConditionGroups(newGroups);
  };

  const handleGroupRelationChange = (index, value) => {
    const newRelations = [...groupRelations];
    newRelations[index] = value;
    setGroupRelations(newRelations);
  };

  const addYesAction = () => {
    setYesActions([...yesActions, { type: 'action', action: null, config: {}, parentConditionId: conditionId }]);
  };

  const addNoAction = () => {
    setNoActions([...noActions, { type: 'action', action: null, config: {}, parentConditionId: conditionId }]);
  };

  const handleSelectAction = (index, action, isYesAction = true) => {
    const newActions = isYesAction ? [...yesActions] : [...noActions];
    newActions[index].action = action;
    isYesAction ? setYesActions(newActions) : setNoActions(newActions);
  };

  const handleDeleteAction = (index, isYesAction = true) => {
    const newActions = isYesAction ? yesActions.filter((_, i) => i !== index) : noActions.filter((_, i) => i !== index);
    isYesAction ? setYesActions(newActions) : setNoActions(newActions);
  };

  const handleActionConfigChange = (index, config, isYesAction = true) => {
    const newActions = isYesAction ? [...yesActions] : [...noActions];
    newActions[index].config = config;
    isYesAction ? setYesActions(newActions) : setNoActions(newActions);
  };

  return (
    <div className="condition-action">
      <div className="condition-groups">
        {conditionGroups.map((group, index) => (
          <div key={index} className="condition-group-wrapper">
            <ConditionGroup
              group={group}
              onGroupChange={(updatedGroup) => handleGroupChange(index, updatedGroup)}
              onDeleteGroup={() => handleDeleteGroup(index)}
              onAddCondition={() => handleAddCondition(index)}
              isRemovable={index > 0}
              buttonLabels={buttonLabels}
              templateIds={templateIds}
              parent={'ConditionAction'}
              isGeneral={isGeneral}
              onToggleGeneral={() => setIsGeneral(!isGeneral)}
            />
          </div>
        ))}
        <div className="add-group-wrapper">
          <button className='condition-action-add-group-wrapper' type="button" onClick={addGroup}>Add Group</button>
          {conditionGroups.length > 0 && (
            <select
              value={groupRelations[groupRelations.length - 1] || 'AND'}
              onChange={(e) => handleGroupRelationChange(groupRelations.length - 1, e.target.value)}
              className="group-relation-select"
            >
              <option value="AND">AND</option>
              <option value="OR">OR</option>
            </select>
          )}
        </div>
      </div>

      <div className="condition-scopes">
        <div className="scope">
          <h4>If yes</h4>
          {yesActions.map((action, index) => (
            <div key={index} className="scope-action">
              <div className="step-controls">
                <button onClick={() => handleDeleteAction(index, true)} className="delete-action-button">Delete</button>
              </div>
              {!action.action && <h3>Select Action</h3>}
              <select
                onChange={(e) => handleSelectAction(index, e.target.value, true)}
                value={action.action || ''}
              >
                <option value="">Select Action Type</option>
                <option value="Condition">Condition</option>
                <option value="SendMessage">Send Message</option>
                <option value="IncomingMessage">Incoming Message</option>
                <option value="SendHttpRequest">Send HTTP Request</option>
              </select>
              {action.action === 'Condition' && (
                <ConditionAction
                  onChange={(config) => handleActionConfigChange(index, config, false)}
                  steps={steps}
                  currentUser={currentUser}
                  conditionId={`${conditionId}-no-${index}`}
                  initialConfig={action.config}
                />
              )}
              {action.action === 'SendMessage' && (
                <OutboundMessageAction
                  organization={currentUser?.organization}
                  onChange={(config) => handleActionConfigChange(index, config, true)}
                  initialConfig={action.config}
                />
              )}
              {action.action === 'SendHttpRequest' && (
                <SendHttpRequestAction
                  organization={currentUser?.organization}
                  onChange={(config) => handleActionConfigChange(index, config, false)}
                  initialConfig={action.config}
                />
              )}
              {action.action === 'IncomingMessage' && (
                <IncomingMessageTrigger
                  organization={currentUser?.organization}
                  onChange={(config) => handleActionConfigChange(index, config)}
                  initialConfig={action.config}
                />
              )}
            </div>
          ))}
          <button className='condition-action-add-action' type="button" onClick={addYesAction}>+ Add Action</button>
        </div>

        <div className="scope">
          <h4>If no</h4>
          {noActions.map((action, index) => (
            <div key={index} className="scope-action">
              <div className="step-controls">
                <button onClick={() => handleDeleteAction(index, false)} className="delete-action-button">Delete</button>
              </div>
              {!action.action && <h3>Select Action</h3>}
              <select
                onChange={(e) => handleSelectAction(index, e.target.value, false)}
                value={action.action || ''}
              >
                <option value="">Select Action Type</option>
                <option value="Condition">Condition</option>
                <option value="SendMessage">Send Message</option>
                <option value="IncomingMessage">Incoming Message</option>
                <option value="SendHttpRequest">Send HTTP Request</option>
              </select>
              {action.action === 'Condition' && (
                <ConditionAction
                  onChange={(config) => handleActionConfigChange(index, config, false)}
                  steps={steps}
                  currentUser={currentUser}
                  conditionId={`${conditionId}-no-${index}`}
                  initialConfig={action.config}
                />
              )}
              {action.action === 'SendMessage' && (
                <OutboundMessageAction
                  organization={currentUser?.organization}
                  onChange={(config) => handleActionConfigChange(index, config, false)}
                  initialConfig={action.config}
                />
              )}
              {action.action === 'SendHttpRequest' && (
                <SendHttpRequestAction
                  organization={currentUser?.organization}
                  onChange={(config) => handleActionConfigChange(index, config, false)}
                  initialConfig={action.config}
                />
              )}
              {action.action === 'IncomingMessage' && (
                <IncomingMessageTrigger
                  organization={currentUser?.organization}
                  onChange={(config) => handleActionConfigChange(index, config)}
                  initialConfig={action.config}
                />
              )}
            </div>
          ))}
          <button className='condition-action-add-action' type="button" onClick={addNoAction}>+ Add Action</button>
        </div>
      </div>
    </div>
  );
};

export default ConditionAction;
