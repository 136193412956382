import React from 'react';
import ConditionRow from './ConditionRow';

const ConditionGroup = ({ group, onGroupChange, onDeleteGroup, onAddCondition, isRemovable, parent, buttonLabels, templateIds, isGeneral, onToggleGeneral }) => {
  const handleLogicOperatorChange = (e) => {
    onGroupChange({ ...group, logicOperator: e.target.value });
  };
  console.log(group);
  console.log(buttonLabels);
  const handleConditionChange = (index, updatedCondition) => {
    const newConditions = [...group.conditions];
    newConditions[index] = updatedCondition;
    onGroupChange({ ...group, conditions: newConditions });
  };

  const handleAddCondition = () => {
    onAddCondition();
  };

  const handleDeleteCondition = (index) => {
    const newConditions = group.conditions.filter((_, i) => i !== index);
    onGroupChange({ ...group, conditions: newConditions });
  };

  return (
    <div className="condition-group">
      <label>
        Logic Operator:
        <select value={group.logicOperator} onChange={handleLogicOperatorChange}>
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </select>
      </label>
      {group.conditions.map((condition, index) => (
        <ConditionRow
          key={index}
          condition={condition}
          onConditionChange={(updatedCondition) => handleConditionChange(index, updatedCondition)}
          onDelete={() => handleDeleteCondition(index)}
          parent={parent}
          buttonLabels={buttonLabels}
          templateIds={templateIds}
          isGeneral={isGeneral}
          onToggleGeneral={onToggleGeneral}
        />
      ))}
      <button className='condition-group-add-action' type="button" onClick={handleAddCondition}>Add Condition</button>
      {isRemovable && <button type="button" onClick={onDeleteGroup}>Remove Group</button>}
    </div>
  );
};

export default ConditionGroup;
