import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css'

function UserProfile({ name, photoURL, lastMessage, email, time, contact, onClick }) { // Add onClick prop
    const navigate = useNavigate();
    const goToContact = (contactPhoneNumber, name) => {
        if (contactPhoneNumber) {
            onClick(contact);
             navigate(`/${contactPhoneNumber}/${name}`);
            //navigate(`/${contactPhoneNumber}`);
        }
    }
    console.log(photoURL);
    return (
        <div className='user-profile' onClick={() => goToContact(contact.phoneNumber, name)}>
            <div className='user-image'>
                <img src={photoURL} alt='' />
            </div>
            <div className='user-info'>
                <p className='user-name'>{name}</p>
                {lastMessage && (<p className='user-lastmessage'>{lastMessage + ' ' + time}</p>)}
            </div>
        </div>
    );
}

export default UserProfile;
