import React from 'react';
import { Settings, Contacts, ChatBubbleOutline, Timeline, Storage } from '@mui/icons-material'; // Import MUI Icons
import SchemaIcon from '@mui/icons-material/Schema';
import ChatIcon from '@mui/icons-material/Chat';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import CampaignIcon from '@mui/icons-material/Campaign';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
export const MenuDATA = [
  {
    title: 'Settings',
    path: '/settings',
    icon: <Settings />,
    cName: 'nav-text'
  },
  {
    title: 'Contacts',
    path: '/contacts',
    icon: <Contacts />,
    cName: 'nav-text'
  },
  {
    title: 'Chats',
    path: '/chat',
    icon: <ChatBubbleOutline />,
    cName: 'nav-text'
  },
  {
    title: 'Templates',
    path: '/Templates',
    icon: <ChatIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Campaign',
    path: '/Campaign',
    icon: <CampaignIcon />,
    cName: 'nav-text'
  },
  {
    title: 'FormFlows',
    path: '/FormFlows',
    icon: <DynamicFormIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Botomations',
    path: '/Botomations',
    icon: <SchemaIcon  />,
    cName: 'nav-text'
  },
  
  {
    title: 'Integrations',
    path: '/Integrations',
    icon: <IntegrationInstructionsIcon />,
    cName: 'nav-text'
  }
  
  
];
