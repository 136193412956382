import React, { useState } from 'react';
import './FormFlowsGenerator.css';
import FormFlowsScreens from './FormFlowsScreens';
import FormFlowsEditContent from './FormFlowsEditContent';
import FormFlowsPreview from './FormFlowsPreview';

const FormFlowsGenerator = () => {
  const initialFlow = {
    id: Date.now(),
    name: 'Flow 1',
    screens: [{ id: Date.now(), title: 'First Screen', content: [], button: 'Continue' }]
  };

  const [flow, setFlow] = useState(initialFlow);
  const [selectedScreen, setSelectedScreen] = useState(initialFlow.screens[0]);

  const handleAddScreen = () => {
    const newScreen = {
      id: Date.now(),
      title: `Screen ${flow.screens.length + 1}`,
      content: [],
      button: 'Continue'
    };
    const updatedFlow = { ...flow, screens: [...flow.screens, newScreen] };

    setFlow(updatedFlow);
    setSelectedScreen(newScreen);
  };

  const handleSelectScreen = (screenId) => {
    setSelectedScreen(flow.screens.find(screen => screen.id === screenId));
  };

  const handleContentChange = (screenId, updatedContent) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, content: updatedContent } : screen
    );

    const updatedFlow = { ...flow, screens: updatedScreens };

    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };

  const handleTitleChange = (screenId, updatedTitle) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, title: updatedTitle } : screen
    );

    const updatedFlow = { ...flow, screens: updatedScreens };

    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };

  const handleRemoveScreen = (screenId) => {
    const updatedScreens = flow.screens.filter(screen => screen.id !== screenId);
    const updatedFlow = { ...flow, screens: updatedScreens };

    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.length > 0 ? updatedScreens[0] : null);
  };
  console.log(selectedScreen);
  return (
    <div className="form-flows-generator-container">
      <FormFlowsScreens
        flow={flow}
        onAddScreen={handleAddScreen}
        onSelectScreen={handleSelectScreen}
        onRemoveScreen={handleRemoveScreen}
      />
      <FormFlowsEditContent
        selectedScreen={selectedScreen}
        onContentChange={handleContentChange}
        onTitleChange={handleTitleChange}
      />
      <FormFlowsPreview selectedScreen={selectedScreen} />
    </div>
  );
};

export default FormFlowsGenerator;
