import React, { useState, useEffect, useRef } from 'react';
import Switch from 'react-switch';

const operators = [
  "equals", 
  "not equals", 
  "contains", 
  "does not contain", 
  "begins with", 
  "does not begin with", 
  "ends with", 
  "does not end with", 
  "today's date",
  "Is Conversation Live?",
  "Is In Middle Of Botomation?"
];

const ConditionRow = ({ condition, onConditionChange, onDelete, parent, buttonLabels = [], templateIds = [], isGeneral, onToggleGeneral }) => {
  const initialSet = useRef(false);
  console.log(condition);
  // Set the field based on the condition or default to "ResponseLastSentTemplateMessageButtonClicked" if the parent is ConditionAction
  const [field, setField] = useState(() => {
    if (condition.field) {
      return condition.field;
    } else if (parent === "ConditionAction") {
      return "ResponseLastSentTemplateMessageButtonClicked";
    } else {
      return "message";
    }
  });

  useEffect(() => {
    if (parent === "ConditionAction" && !initialSet.current && !condition.field) {
      const defaultValue = buttonLabels.length > 0 && templateIds.length > 0 ? `${buttonLabels[0]} + ${templateIds[0]}` : '';
      onConditionChange({ ...condition, field: "ResponseLastSentTemplateMessageButtonClicked", value: defaultValue, templateId: templateIds[0] });
      initialSet.current = true; // Mark the initial setting as done
    }
  }, [parent, buttonLabels, templateIds, condition, onConditionChange]);
  
  const handleFieldChange = (e) => {
    const newField = e.target.value;
    let newValue = '';
    let newTemplateId = '';

    if (newField === 'ResponseLastSentTemplateMessageButtonClicked') {
      if (buttonLabels.length > 0 && templateIds.length > 0) {
        newValue = `${buttonLabels[0]} + ${templateIds[0]}`;
        newTemplateId = templateIds[0];
      }
    }

    setField(newField);
    onConditionChange({ ...condition, field: newField, value: newValue, templateId: newTemplateId });
  };

  const handleOperatorChange = (e) => {
    onConditionChange({ ...condition, operator: e.target.value });
  };

  const handleValueChange = (e) => {
    let newValue = e.target.value;
    let newTemplateId = '';

    if (field === 'ResponseLastSentTemplateMessageButtonClicked') {
      const index = buttonLabels.findIndex(label => `${label} + ${templateIds[buttonLabels.indexOf(label)]}` === newValue);
      newTemplateId = templateIds[index];
    }

    if (condition.operator === "Is Conversation Live?" || condition.operator === "Is In Middle Of Botomation?") {
      newValue = e.target.value === 'true'; // Convert to boolean
    }

    onConditionChange({ ...condition, value: newValue, templateId: newTemplateId });
  };

  const handleToggleChange = (checked) => {
    onToggleGeneral();
  };

  useEffect(() => {
    if (field === 'ResponseLastSentTemplateMessageButtonClicked' && buttonLabels.length > 0 && templateIds.length > 0 && condition.value === '') {
      onConditionChange({ ...condition, value: `${buttonLabels[0]} + ${templateIds[0]}`, templateId: templateIds[0] });
    }
  }, [field, buttonLabels, templateIds, onConditionChange, condition]);
  console.log(buttonLabels);
  return (
    <div className="condition-row">
      <select value={field} onChange={handleFieldChange}>
        {parent === "ConditionAction" ? (
          <>
            <option value="ResponseLastSentTemplateMessageButtonClicked">Response Last Sent Message - Template Button Clicked</option>
            <option value="message">Message</option>
          </>
        ) : (
          <option value="message">Message</option>
        )}
      </select>
      <select value={condition.operator} onChange={handleOperatorChange}>
        {operators.map(op => (
          <option key={op} value={op}>{op}</option>
        ))}
      </select> 
      {field === "ResponseLastSentTemplateMessageButtonClicked" ? (
        <select value={condition.value} onChange={handleValueChange}>
          {buttonLabels.map((label, index) => (
            <option key={index} value={`${label} + ${templateIds[index]}`}>{`${label} + ${templateIds[index]}`}</option>
          ))}
        </select>
      ) : (condition.operator === "Is Conversation Live?" || condition.operator === "Is In Middle Of Botomation?") ? (
        <select value={condition.value.toString()} onChange={handleValueChange}>
          <option value="true">true</option>
          <option value="false">false</option>
        </select>
      ) : (
        <input type="text" value={condition.value} onChange={handleValueChange} />
      )}
      <button type="button" onClick={onDelete}>Remove</button>
      
      <div className="toggle-switch">
        <span>{isGeneral ? "Always" : "This"}</span>
        <Switch
          onChange={handleToggleChange}
          checked={isGeneral}
          offColor="#888"
          onColor="#00D084"
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          handleDiameter={20}
        />
      </div>
    </div>
  );
};

export default ConditionRow;
