import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import './GambotAction.css';

const GambotAction = ({ organization, onChange, initialConfig = {} }) => {
    const [table, setTable] = useState(initialConfig.table || 'Contact');
    const [actionType, setActionType] = useState(initialConfig.actionType || 'create');
    const [createSource, setCreateSource] = useState(initialConfig.createSource || 'incomingMessage');
    const [createWebhookObjectPath, setCreateWebhookObjectPath] = useState(initialConfig.createWebhookObjectPath || '');
    const [updateOption, setUpdateOption] = useState(initialConfig.updateOption || 'selectField');
    const [selectedFields, setSelectedFields] = useState(initialConfig.selectedFields || []);
    const [incomingParameters, setIncomingParameters] = useState(initialConfig.incomingParameters || '');
    const [contactsColumns, setContactsColumns] = useState([]);
    const [processSource, setProcessSource] = useState(initialConfig.processSource || 'incomingMessage');
    const [processWebhookObjectPath, setProcessWebhookObjectPath] = useState(initialConfig.processWebhookObjectPath || '');
    const [keyOption, setKeyOption] = useState(initialConfig.keyOption || 'addKey');
    const [keyValue, setKeyValue] = useState(initialConfig.keyValue || '');
    const [phoneSource, setPhoneSource] = useState(initialConfig.phoneSource || 'incomingMessage');
    const [webhookObjectPath, setWebhookObjectPath] = useState(initialConfig.webhookObjectPath || '');
    const [keySource, setKeySource] = useState(initialConfig.keySource || 'incomingMessage');
    const [keyWebhookObjectPath, setKeyWebhookObjectPath] = useState(initialConfig.keyWebhookObjectPath || '');

    useEffect(() => {
        const fetchContactsColumns = async (organization) => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetContactsColumnsByOrg', {
                    organization: organization
                });
                setContactsColumns(response.data);
                console.log("Contacts columns fetched:", response.data);
            } catch (error) {
                console.error('Error fetching contacts columns:', error);
            }
        };
        fetchContactsColumns(organization);
    }, [organization]);

    const handleActionChange = (e) => {
        const newActionType = e.target.value;
        setActionType(newActionType);

        // Reset related states when action type changes
        setCreateSource('incomingMessage');
        setCreateWebhookObjectPath('');
        setUpdateOption('selectField');
        setSelectedFields([]);
        setIncomingParameters('');
        setProcessSource('incomingMessage');
        setProcessWebhookObjectPath('');
        setKeyOption('addKey');
        setKeyValue('');
        setPhoneSource('incomingMessage');
        setWebhookObjectPath('');
        setKeySource('incomingMessage');
        setKeyWebhookObjectPath('');

        onChange({
            table,
            actionType: newActionType,
            createSource: newActionType === 'create' ? createSource : null,
            createWebhookObjectPath: newActionType === 'create' ? createWebhookObjectPath : '',
            updateOption: newActionType === 'update' ? updateOption : null,
            selectedFields: [],
            incomingParameters: '',
            processSource: newActionType === 'update' && updateOption === 'processByParams' ? processSource : null,
            processWebhookObjectPath: newActionType === 'update' && updateOption === 'processByParams' ? processWebhookObjectPath : '',
            keyOption: newActionType === 'keys' ? keyOption : null,
            keyValue: '',
            phoneSource: newActionType === 'spam' ? phoneSource : null,
            webhookObjectPath: '',
            keySource: newActionType === 'keys' ? keySource : null,
            keyWebhookObjectPath: '',
        });
    };

    const handleCreateSourceChange = (e) => {
        const newCreateSource = e.target.value;
        setCreateSource(newCreateSource);
        setCreateWebhookObjectPath('');

        onChange({
            table,
            actionType,
            createSource: newCreateSource,
            createWebhookObjectPath: '',
        });
    };

    const handleCreateWebhookObjectPathChange = (e) => {
        const path = e.target.value;
        setCreateWebhookObjectPath(path);
        onChange({
            table,
            actionType,
            createSource,
            createWebhookObjectPath: path,
        });
    };

    const handleUpdateOptionChange = (e) => {
        const newUpdateOption = e.target.value;
        setUpdateOption(newUpdateOption);
        setSelectedFields([]);
        setIncomingParameters('');
        setProcessSource('incomingMessage');
        setProcessWebhookObjectPath('');

        onChange({
            table,
            actionType,
            updateOption: newUpdateOption,
            selectedFields: [],
            incomingParameters: '',
            processSource: newUpdateOption === 'processByParams' ? processSource : null,
            processWebhookObjectPath: newUpdateOption === 'processByParams' ? processWebhookObjectPath : '',
        });
    };

    const handleFieldsChange = (selectedOptions) => {
        const updatedFields = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setSelectedFields(updatedFields);
        onChange({
            table,
            actionType,
            updateOption,
            selectedFields: updatedFields,
            incomingParameters,
        });
    };

    const handleParametersChange = (e) => {
        const params = e.target.value;
        setIncomingParameters(params);
        onChange({
            table,
            actionType,
            updateOption,
            selectedFields,
            incomingParameters: params,
        });
    };

    const handleProcessSourceChange = (e) => {
        const newProcessSource = e.target.value;
        setProcessSource(newProcessSource);
        setProcessWebhookObjectPath('');

        onChange({
            table,
            actionType,
            updateOption,
            processSource: newProcessSource,
            processWebhookObjectPath: '',
        });
    };

    const handleProcessWebhookObjectPathChange = (e) => {
        const path = e.target.value;
        setProcessWebhookObjectPath(path);
        onChange({
            table,
            actionType,
            updateOption,
            processSource,
            processWebhookObjectPath: path,
        });
    };

    const handleKeyOptionChange = (e) => {
        const newKeyOption = e.target.value;
        setKeyOption(newKeyOption);
        setKeyValue('');

        onChange({
            table,
            actionType,
            keyOption: newKeyOption,
            keyValue: '',
            keySource,
            keyWebhookObjectPath,
        });
    };

    const handleKeyValueChange = (e) => {
        const value = e.target.value;
        setKeyValue(value);
        onChange({
            table,
            actionType,
            keyOption,
            keyValue: value,
            keySource,
            keyWebhookObjectPath,
        });
    };

    const handlePhoneSourceChange = (e) => {
        const newPhoneSource = e.target.value;
        setPhoneSource(newPhoneSource);
        setWebhookObjectPath('');

        onChange({
            table,
            actionType,
            phoneSource: newPhoneSource,
            webhookObjectPath: '',
        });
    };

    const handleWebhookObjectPathChange = (e) => {
        const path = e.target.value;
        setWebhookObjectPath(path);
        onChange({
            table,
            actionType,
            phoneSource,
            webhookObjectPath: path,
        });
    };

    const handleKeySourceChange = (e) => {
        const newKeySource = e.target.value;
        setKeySource(newKeySource);
        setKeyWebhookObjectPath('');

        onChange({
            table,
            actionType,
            keyOption,
            keyValue,
            keySource: newKeySource,
            keyWebhookObjectPath: '',
        });
    };

    const handleKeyWebhookObjectPathChange = (e) => {
        const path = e.target.value;
        setKeyWebhookObjectPath(path);
        onChange({
            table,
            actionType,
            keyOption,
            keyValue,
            keySource,
            keyWebhookObjectPath: path,
        });
    };

    const fieldOptions = contactsColumns.map(column => ({
        value: column,
        label: column,
    }));

    return (
        <div className="gambot-action">
            <div className="form-group">
                <label htmlFor="table">Table:</label>
                <input
                    id="table"
                    type="text"
                    value={table}
                    disabled
                    className="form-control"
                />
            </div>

            <div className="form-group">
                <label htmlFor="actionType">Action Type:</label>
                <select
                    id="actionType"
                    value={actionType}
                    onChange={handleActionChange}
                    className="form-control"
                >
                    <option value="create">Create New Contact</option>
                    <option value="update">Update Contact</option>
                    <option value="spam">Set as Spam</option>
                    <option value="keys">Manage Keys</option>
                </select>
            </div>

            {actionType === 'create' && (
                <div className="form-group">
                    <label htmlFor="createSource">Create Source:</label>
                    <select
                        id="createSource"
                        value={createSource}
                        onChange={handleCreateSourceChange}
                        className="form-control"
                    >
                        <option value="incomingMessage">Incoming Message</option>
                        <option value="webhook">Webhook</option>
                    </select>

                    {createSource === 'webhook' && (
                        <div className="form-group mt-3">
                            <label htmlFor="createWebhookObjectPath">Webhook Object Path:</label>
                            <input
                                id="createWebhookObjectPath"
                                type="text"
                                placeholder="e.g., data.attributes.contact"
                                value={createWebhookObjectPath}
                                onChange={handleCreateWebhookObjectPathChange}
                                className="form-control"
                            />
                        </div>
                    )}
                </div>
            )}

            {actionType === 'update' && (
                <>
                    <div className="form-group">
                        <label htmlFor="updateOption">Update Option:</label>
                        <select
                            id="updateOption"
                            value={updateOption}
                            onChange={handleUpdateOptionChange}
                            className="form-control"
                        >
                            {/* <option value="selectField">Select Fields to Update</option> */}
                            <option value="Choose">Select Your Choice</option>
                            <option value="processByParams">Process by Request Parameters</option>
                        </select>
                    </div>

                    {/* {updateOption === 'selectField' && (
                        <div className="form-group">
                            <label>Fields to Update:</label>
                            <Select
                                isMulti
                                options={fieldOptions}
                                onChange={handleFieldsChange}
                                value={fieldOptions.filter(option => selectedFields.includes(option.value))}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                        </div>
                    )} */}

                    {updateOption === 'processByParams' && (
                        <div className="form-group">
                            <label htmlFor="processSource">Process Source:</label>
                            <select
                                id="processSource"
                                value={processSource}
                                onChange={handleProcessSourceChange}
                                className="form-control"
                            >
                                <option value="incomingMessage">Incoming Message</option>
                                <option value="webhook">Webhook</option>
                            </select>

                            {processSource === 'webhook' && (
                                <div className="form-group mt-3">
                                    <label htmlFor="processWebhookObjectPath">Webhook Object Path:</label>
                                    <input
                                        id="processWebhookObjectPath"
                                        type="text"
                                        placeholder="e.g., data.attributes.phoneNumber"
                                        value={processWebhookObjectPath}
                                        onChange={handleProcessWebhookObjectPathChange}
                                        className="form-control"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}

            {actionType === 'spam' && (
                <div className="form-group">
                    <label htmlFor="phoneSource">Phone Number Source:</label>
                    <select
                        id="phoneSource"
                        value={phoneSource}
                        onChange={handlePhoneSourceChange}
                        className="form-control"
                    >
                        <option value="incomingMessage">Incoming Message</option>
                        <option value="webhook">Webhook</option>
                    </select>

                    {phoneSource === 'webhook' && (
                        <div className="form-group mt-3">
                            <label htmlFor="webhookObjectPath">Webhook Object Path:</label>
                            <input
                                id="webhookObjectPath"
                                type="text"
                                placeholder="e.g., data.attributes.phoneNumber"
                                value={webhookObjectPath}
                                onChange={handleWebhookObjectPathChange}
                                className="form-control"
                            />
                        </div>
                    )}
                </div>
            )}

            {actionType === 'keys' && (
                <>
                    <div className="form-group">
                        <label htmlFor="keyOption">Key Option:</label>
                        <select
                            id="keyOption"
                            value={keyOption}
                            onChange={handleKeyOptionChange}
                            className="form-control"
                        >
                            <option value="addKey">Add New Key</option>
                            <option value="deleteKey">Delete Key</option>
                        </select>
                    </div>                    
                    <div className="form-group">
                        <label htmlFor="keySource">Key Source:</label>
                        <select
                            id="keySource"
                            value={keySource}
                            onChange={handleKeySourceChange}
                            className="form-control"
                        >
                            <option value="incomingMessage">Incoming Message</option>
                            <option value="webhook">Webhook</option>
                        </select>

                        {keySource === 'webhook' && (
                            <div className="form-group mt-3">
                                <label htmlFor="keyWebhookObjectPath">Webhook Object Path:</label>
                                <input
                                    id="keyWebhookObjectPath"
                                    type="text"
                                    placeholder="e.g., data.attributes.keyName"
                                    value={keyWebhookObjectPath}
                                    onChange={handleKeyWebhookObjectPathChange}
                                    className="form-control"
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default GambotAction;
