import React from 'react';
import { NavLink } from 'react-router-dom';
import './Settings.css'; // Make sure to create a corresponding CSS file for styling
import Menu from './Menu';

const Settings = () => {
    return (
        <div className="Settings">
            {/* <Menu></Menu> */}
            
        </div>
    );
}

export default Settings;
