import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WorkflowDesigner from './WorkflowDesigner/WorkflowDesigner';
import './BotomationForm.css';

const BotomationForm = ({ currentUser }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [botomationData, setBotomationData] = useState({
        name: '',
        status: '',
        Steps: [] // Ensure steps is initialized as an empty array
    });

    const botomationID = useMemo(() => searchParams.get('Id'), [searchParams]);
    const organization = useMemo(() => searchParams.get('organization'), [searchParams]);
    const userEmail = useMemo(() => searchParams.get('userEmail'), [searchParams]);
    const isEdit = useMemo(() => searchParams.has('Id'), [searchParams]);

    useEffect(() => {
        const fetchBotomationData = async () => {
            if (botomationID) {
                try {
                    const response = await axios.post(
                        "https://gambot.azurewebsites.net/api/Webhooks/GetBotomationById",
                        {
                            organization: organization,
                            BotomationId: botomationID
                        },
                        {
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );
                    const fetchedData = response.data;
                    console.log("Fetched botomation data:", fetchedData);
                    setBotomationData(fetchedData);
                } catch (error) {
                    console.error('Error fetching botomation:', error);
                }
            }
        };
        fetchBotomationData();
    }, [botomationID, organization]); // Removed botomationData.name from dependencies

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(botomationData);
        const endpoint = isEdit ? 'UpdateBotomation' : 'CreateBotomation';
        try {
            const response = await axios.post(`https://gambot.azurewebsites.net/api/Webhooks/${endpoint}`, {
                organization: organization,
                botomationData: botomationData,
            });
            setSuccessMessage(`Botomation ${isEdit ? 'updated' : 'created'} successfully!`);
            console.log("Botomation submitted:", response.data);
            navigate('/Botomations');
        } catch (error) {
            console.error('Error submitting botomation:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBotomationData(prevData => ({ ...prevData, [name]: value }));
    };

    // const handleStepsChange = (newSteps) => {
    //     setBotomationData(prevData => ({ ...prevData, Steps: newSteps }));
    // };
    const handleStepsChange = (newSteps) => {
        setBotomationData(prevData => {
            // Check if Steps have actually changed
            if (JSON.stringify(prevData.Steps) !== JSON.stringify(newSteps)) {
                return { ...prevData, Steps: newSteps };
            }
            return prevData;
        });
    };

    const handleBackButtonClick = useCallback(() => {
        navigate('/Botomations');
    }, [navigate]);

    console.log(botomationData);

    return (
        <div className='botomation-container'>
            <form onSubmit={handleSubmit} className='botomation-form'>
                <div className='botomation-form-header'>
                    <IconButton onClick={handleBackButtonClick} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <h2 className='botomation-form-header-h2'>{isEdit ? "Update" : "Create"} Botomation - Workflow Designer </h2>
                </div>
                <div className='botomation-form-row'>
                    <input 
                        placeholder='Insert Botomation Name' 
                        className='botomation-form-row-name-input' 
                        type="text" 
                        name="name" 
                        value={botomationData.name} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                {/* {isEdit && (
                    <div className='botomation-form-row'>
                        <label>
                            Status:
                            <input 
                                type="text" 
                                name="status" 
                                value={botomationData.status} 
                                onChange={handleChange} 
                                required 
                            />
                        </label>
                    </div>
                )} */}
                <WorkflowDesigner 
                    currentUser={currentUser} 
                    steps={botomationData.Steps} 
                    setSteps={handleStepsChange} 
                />
                <button className='botomation-form-submit' type="submit">Submit</button>
            </form>
        </div>
    );
};

export default BotomationForm;
