import React, { useState } from 'react';
import styled from 'styled-components';

const TagInputContainer = styled.div`
  border: 1px solid #ccc;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  padding: 5px;
  background-color: #e2e2e2;
  margin-right: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
`;

const TagInput = styled.input`
  flex: 1;
  border: none;
  padding: 5px;
  &:focus {
    outline: none;
  }
`;

const ContactsKeys = ({ onChange }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  console.log(tags);
  console.log(inputValue);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();  // Prevent form submission
      if (inputValue) {
        setTags([...tags, inputValue]);
        setInputValue('');
        onChange([...tags, inputValue]); // Update the parent component
      }
    }
  };
  

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const removeTag = (indexToRemove) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);
    onChange(newTags);  // Update the parent component
  };

  return (
    <TagInputContainer>
      {tags.map((tag, index) => (
        <Tag key={index}>
          {tag}
          <button type="button" onClick={() => removeTag(index)}>x</button>
        </Tag>
      ))}
      <TagInput
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type and press Enter"
      />
    </TagInputContainer>
  );
};

export default ContactsKeys;
