import React, { useState } from 'react';
import './FormParamContent.css'; // Ensure this is imported for styling

const FormParamContent = ({ contentItem, onUpdateContent, onDeleteContent }) => {
  const [inputValue, setInputValue] = useState(contentItem.content || '');
  const [isRequired, setIsRequired] = useState(contentItem.isRequired || false);
  const [options, setOptions] = useState(contentItem.options || []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onUpdateContent(contentItem.id, { ...contentItem, content: value });
  };

  const handleToggleRequired = () => {
    setIsRequired(!isRequired);
    onUpdateContent(contentItem.id, { ...contentItem, isRequired: !isRequired });
  };

  const handleDelete = () => {
    onDeleteContent(contentItem.id);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = options.map((option, i) =>
      i === index ? value : option
    );
    setOptions(updatedOptions);
    onUpdateContent(contentItem.id, { ...contentItem, options: updatedOptions });
  };

  const handleAddOption = () => {
    const newOption = `Option ${options.length + 1}`;
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    onUpdateContent(contentItem.id, { ...contentItem, options: updatedOptions });
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    onUpdateContent(contentItem.id, { ...contentItem, options: updatedOptions });
  };

  const renderInputField = () => {
    switch (contentItem.type) {
      case 1.1: // Large Heading
      case 1.2: // Small Heading
      case 1.3: // Caption
      case 1.4: // Body
        return (
          <textarea
            value={inputValue}
            onChange={handleInputChange}
            placeholder={`Enter ${contentItem.name.toLowerCase()} (max 80 characters)`}
            maxLength={80}
            style={{ fontSize: contentItem.type === 1.1 ? '24px' : contentItem.type === 1.2 ? '16px' : '14px' }}
          />
        );
      case 2.1: // Image
        return <input type="file" accept="image/*" onChange={handleInputChange} />;
      case 3.1: // Short Answer
      case 3.2: // Paragraph
        return (
          <textarea
            value={inputValue}
            onChange={handleInputChange}
            placeholder={`Enter ${contentItem.name.toLowerCase()}`}
          />
        );
      case 3.3: // Date Picker
        return <input type="date" value={inputValue} onChange={handleInputChange} />;
      case 4.1: // Single Choice
      case 4.2: // Multiple Choice
        return (
          <div>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Label"
            />
            <div className="formParamContent-options-container">
              <span>Options</span>
              {options.map((option, index) => (
                <div key={index} className="formParamContent-option-item">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    maxLength={30}
                  />
                  <button className="formParamContent-option-item-delete-button" onClick={() => handleDeleteOption(index)}>
                    <i className="fas fa-trash-alt" />
                  </button>
                </div>
              ))}
              <button className="formParamContent-add-option-button" onClick={handleAddOption}>+ Add Option</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="form-param-content">
      <div className="form-param-header">
        <span>{contentItem.name}</span>
        <button onClick={handleDelete}>Delete</button>
      </div>
      {renderInputField()}
      <div className="form-param-footer">
        <label>
          Required
          <input type="checkbox" checked={isRequired} onChange={handleToggleRequired} />
        </label>
      </div>
    </div>
  );
};

export default FormParamContent;
