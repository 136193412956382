import React from 'react';
import FormParam from './FormParam';
import FormParamContent from './FormParamContent'; // Ensure this is imported
import './FormFlowsEditContent.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FormFlowsEditContent = ({ selectedScreen, onContentChange, onTitleChange }) => {
  if (!selectedScreen) return null;

  const handleContentChange = (id, updatedContent) => {
    const updatedContents = selectedScreen.content.map(contentItem =>
      contentItem.id === id ? updatedContent : contentItem
    );
    onContentChange(selectedScreen.id, updatedContents);
  };

  const handleAddContent = (subOption) => {
    const newContentItem = { id: Date.now().toString(), name: subOption.name, type: subOption.id, content: '', isRequired: false };
    const updatedContents = [...selectedScreen.content, newContentItem];
    onContentChange(selectedScreen.id, updatedContents);
  };

  const handleDeleteContent = (id) => {
    const updatedContents = selectedScreen.content.filter(contentItem => contentItem.id !== id);
    onContentChange(selectedScreen.id, updatedContents);
  };

  const handleTitleChange = (e) => {
    onTitleChange(selectedScreen.id, e.target.value);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedContents = Array.from(selectedScreen.content);
    const [movedItem] = updatedContents.splice(result.source.index, 1);
    updatedContents.splice(result.destination.index, 0, movedItem);

    onContentChange(selectedScreen.id, updatedContents);
  };

  return (
    <div className="FormFlowsEdit-content-section">
      <h2>Edit content</h2>
      <input
        type="text"
        placeholder="Screen title"
        value={selectedScreen.title}
        onChange={handleTitleChange}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="formParamContents">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="droppable-container">
              {selectedScreen.content.map((contentItem, index) => (
                <Draggable key={contentItem.id} draggableId={contentItem.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="content-item"
                    >
                      <FormParamContent
                        contentItem={contentItem}
                        onUpdateContent={handleContentChange}
                        onDeleteContent={handleDeleteContent}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <FormParam onAddContent={handleAddContent} />
    </div>
  );
};

export default FormFlowsEditContent;
