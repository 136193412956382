import React, { useState } from 'react';
import './HomePage.css'
import GambotLogo from '../../assets/GamBot_Logo.png'; // Adjust the path as necessary
import GambotCover from '../../assets/Cover.png'; // Adjust the path as necessary

const HomePage = ({ setUser })=>{
    const [lead, setLead] = useState({
        name: '',
        email: '',
        phone: '',
      });


      const handleInputChange = (e) => {
        setLead({ ...lead, [e.target.name]: e.target.value });
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle the form submission, send data to your backend or email service
        console.log(lead);
      };
      return (
        <div className="HomePage">
            
            <header className="HomePage-header">
                <div className="header-logo">
                    <img src={GambotLogo} alt="Gambot Logo" />
                </div>
                <div className="HomePage-header-text">
  <h1>Streamline Your Business with Intelligent Automation</h1>
  <p>Discover the power of our driven conversations and seamless automation with Gambot.</p>
</div>

            </header>
    
            <section className="HomePage-content">
  <h2>Why Choose Gambot?</h2>
  <ul>
    <li><strong>Experience</strong> We have done this countless times in the past</li>
    <li><strong>You Can Custom </strong>Create automations and other wonders with the help of our user interface</li>
    <li><strong>Scalable Solutions</strong> Easily scale your customer service and sales operations without the overhead costs</li>
    <li><strong>Easily interfaced</strong> Our system allows convenient data connection to create customized solutions and if necessary we are here to help</li>
  </ul>
  <div className="cta">
    <p> <strong>Ready to transform your customer experience? Sign up today or get in touch to learn more!</strong>  </p>    
  </div>
</section>
    
          <footer className="HomePage-lead-capture">
            <form onSubmit={handleSubmit}>
              <h2 cla>Get in Touch</h2>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={lead.name}
                onChange={handleInputChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={lead.email}
                onChange={handleInputChange}
              />
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone"
                value={lead.phone}
                onChange={handleInputChange}
              />
              <button className='HomePage-lead-capture-submit' type="submit">Submit</button>
            </form>
          </footer>
        </div>
      );
    };
    
    export default HomePage;