import React from 'react';
import './HeaderTypeSelector.css';

const HeaderTypeSelector = ({ selectedType, onChange }) => {
  return (
    <div className='HeaderTypeSelector-container'>
      <label className='HeaderTypeSelector-header'>Select Header Type</label>
      <br />
      <select value={selectedType} onChange={(e) => onChange(e.target.value)}>
        <option value="text">Text</option>
        <option value="media">Media</option>
        <option value="none">None</option>
      </select>
    </div>
  );
};

export default HeaderTypeSelector;
