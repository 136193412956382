import React, { useState, useEffect } from 'react';
import './NewContactForm.css';
import db from '../firebase';
import firebase from 'firebase';
import ContactsKeys from './ContactsKeys';
import axios from 'axios'; // Import Axios

const formatTime = (firebaseTimestamp) => {
  if (!firebaseTimestamp) return '--:--'; // Return default value if timestamp is undefined

  const date = new Date(firebaseTimestamp.seconds * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes}`;
};



function NewContactForm({ onAddContact, currentUser, showForm, toggleForm, closeForm, setContacts }) {
  const [contactName, setContactName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [whatsappError, setWhatsappError] = useState('');

  const [searchKeys, setSearchKeys] = useState([]);
  const handleSearchKeysChange = (newKeys) => {
    setSearchKeys(newKeys);
  };
  console.log(searchKeys);
  useEffect(() => {
    // You can add any additional logic here that should run when the form is initially displayed
  }, []);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isWhatsAppValid = (whatsappNumber) => {
    // Assuming WhatsApp numbers must start with a plus sign (+) followed by digits
    const whatsappRegex = /^\+\d+$/;
    return whatsappRegex.test(whatsappNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email and WhatsApp format
    let validEmail = true;
    let validWhatsApp = true;

    if (!isEmailValid(email)) {
      setEmailError('Please enter a valid email address.');
      validEmail = false;
    }

    if (!isWhatsAppValid(whatsappNumber)) {
      setWhatsappError('Please enter a valid WhatsApp number (e.g., +123456789).');
      validWhatsApp = false;
    }

    if (contactName && validEmail && validWhatsApp) {
      const newContactJson = {
        organization: currentUser.organization,
        email: email,
        name: contactName,
        photoURL: '',
        lastMessage: 'New Contact Created',
        time: formatTime(firebase.firestore.Timestamp.now()),
        from: currentUser.wabaNumber,
        to: whatsappNumber.slice(1),
        phoneNumber: whatsappNumber.slice(1),
        id: whatsappNumber.slice(1),
        keys : searchKeys
      };

      axios
        .post("https://gambot.azurewebsites.net/api/Webhooks/CreateNewContact", newContactJson)
        .then((response) => {
          console.log(response);
          if (response.data && response.data.Success) {
            // Contact creation was successful

            // Update the contacts state with the new contact
            const newContactData = response.data.Data; // Assuming Data contains the new contact details
            setContacts((prevContacts) => [...prevContacts, newContactData]);

            // Close the form and reset form inputs and errors
            closeForm();
            toggleForm();
            setContactName('');
            setWhatsappNumber('');
            setEmail('');
            setEmailError('');
            setWhatsappError('');
          } else {
            // Handle the case where the request was successful, but the server returned an error
            console.error('Error creating contact:', response.data ? response.data.Message : 'Unknown error');
          }
        })
        .catch((error) => {
          // Handle the case where the request itself failed
          console.error('Error adding document:', error);
        });
    }
  };

  return (
<div className={`new-contact-form-modal ${showForm ? 'show' : ''}`}>
      <div className="new-contact-form-modal-content">
      <button className="new-contact-form-close-btn" onClick={closeForm}>
  &times;
</button>
        <h1 className="new-contact-form-header">New Contact Form</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="contactName">Contact Name:</label>
            <input
              type="text"
              id="contactName"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="whatsappNumber">WhatsApp Phone Number:</label>
            <input
              type="text"
              id="whatsappNumber"
              value={whatsappNumber}
              onChange={(e) => setWhatsappNumber(e.target.value)}
            />
            {whatsappError && <p className="error">{whatsappError}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className="error">{emailError}</p>}
          </div>
          <div className='new-contact-keys'>
          <label htmlFor="contactKeys">Contact Group</label>
          <ContactsKeys onChange={handleSearchKeysChange}/>
        </div>
        <button className='new-contact-form-submit' type="submit">Save New Contact</button>
        </form>
      </div>
    </div>
  );
}

export default NewContactForm;
