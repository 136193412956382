import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import './PreviewRun.css';

const PreviewRun = ({ open, onClose, onConfirm, recipients, campaign }) => {
    return (  
      <div className='previewRun-Container'>
        <Dialog className='previewRun-Container-dialog' open={open} onClose={onClose}>
          <DialogTitle className='previewRun-Container-dialog-title'>Confirm Recipients</DialogTitle>
          <DialogContent>
            <Table className='previewRun-Container-dialog-tbl'>
              <TableHead>
                <TableRow>
                  <TableCell>Recipient Name</TableCell>
                  <TableCell>Recipient Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipients.map((recipient, index) => (
                  <TableRow key={index}>
                    <TableCell>{recipient.name}</TableCell>
                    <TableCell>{recipient.phoneNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} color="primary">Send</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

export default PreviewRun;
