import React from 'react';
import Select, { components } from 'react-select';
import { FaSearch, FaPlus } from 'react-icons/fa'; // Ensure you have react-icons installed

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSearch />
    </components.DropdownIndicator>
  );
};

const LookUp = ({ options, onChange, placeholder, entity, defaultValue, allowAddNew,value }) => {
  // Append 'Add New' option  to the options array if allowAddNew is true
  const modifiedOptions = allowAddNew
    ? [...options, { value: 'add_new', label: <><FaPlus /> Add New</> }]
    : options;
  console.log(options);
  console.log(value);
  console.log(defaultValue);
  return (
    <Select
      isMulti={true}
      options={modifiedOptions}
      onChange={onChange}
      placeholder={placeholder}
      value={defaultValue ? defaultValue: value} // Set the default value
      components={{ DropdownIndicator }}
      styles={{ dropdownIndicator: (base) => ({
        ...base,
        padding: 4
      }) }}
    />
  );
};

export default LookUp;
