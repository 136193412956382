import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import './CampaignResult.css';

const CampaignResult = ({ open, onClose, campaignResult }) => {
    const handleClose = () => {
        onClose(); // Close the dialog when onClose is called
    };
    console.log('ss');
    return (
        <div className='campaignResult-container'>
            <Dialog className='campaignResult-container-dialog' open={open} onClose={handleClose}>
                <DialogTitle className='campaignResult-container-dialog-title'>Campaign Result</DialogTitle>
                <DialogContent className='campaignResult-container-dialog-content'>
                    {campaignResult && campaignResult.length > 0 ? (
                        <Table className='campaignResult-container-dialog-tbl'>
                            <TableHead className='campaignResult-container-dialog-tbl-tablehead'>
                                <TableRow>
                                    <TableCell>Runs At</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Runs By Name</TableCell>
                                    <TableCell>Recipient Number</TableCell>
                                    <TableCell>Sent</TableCell>
                                    <TableCell>Delivered</TableCell>
                                    <TableCell>Read</TableCell>
                                    <TableCell>Failed</TableCell>
                                    <TableCell>Press Button</TableCell>
                                    <TableCell>Button Pressed</TableCell>
                                    <TableCell>Reply</TableCell>
                                    <TableCell>Spam</TableCell>
                                    {/* Add more table headers as needed */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {campaignResult.map((result, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{result.RunsAt}</TableCell>
                                        <TableCell>{result.Status}</TableCell>
                                        <TableCell>{result.RunsByName}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.TotalContactsNumber}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfSentMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfDeliveredMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfReadMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfFailuredMessag}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfClickButton}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfIsSpam}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfReplyMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfIsSpam}</TableCell>
                                        {/* Add more cells with campaign result data */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <p>No campaign results available.</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CampaignResult;
