import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ConfirmationDialog from '../ConfirmationDialog';
import './FormFlows.css';
const FormFlows = ({ currentUser }) => {
  console.log(currentUser);
  const navigate = useNavigate();
  const [formFlows, setFormFlows] = useState([]); // State for form flows data
  const [openDialog, setOpenDialog] = useState(false); // State for form flow details dialog
  const [selectedFlow, setSelectedFlow] = useState(null); // State for the selected form flow
  const [flowName, setFlowName] = useState(''); // State for the form flow name
  const [status, setStatus] = useState(''); // State for the form flow status
  const [flowType, setFlowType] = useState(''); // State for the form flow type

  useEffect(() => {
    const fetchData = async () => {
      let orgID = currentUser.organization;
      try {
        const response = await axios.get('https://gambot.azurewebsites.net/api/Webhooks/GetAllFormFlows', {
          params: {
            organizationID: orgID
          }
        });
        console.log(response.data);
        setFormFlows(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [currentUser]);

  const handleDeleteClick = (flow) => {
    setSelectedFlow(flow);
    setOpenDialog(true);
  };

  const handleEditClick = (flow) => {
    console.log(flow);
    const thisFlowQueryString = new URLSearchParams({
      organization: currentUser.organization,
      ...flow,
      components: JSON.stringify(flow.components)
    });
    navigate(`/UpdateFormFlow/?${thisFlowQueryString}`);
  };

  const handleConfirmDelete = () => {
    var payload = {
      organization: currentUser.organization,
      flowId: selectedFlow.id,
      flowName: selectedFlow.name,
    };
    console.log(payload);
    axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteFormFlowByID", payload)
      .then((response) => {
        console.log(response);
        setFormFlows(formFlows.filter(flow => flow.id !== selectedFlow.id));
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="form-flow-list-container">
      <h1 className="form-flow-list-header">Form Flows</h1>
      <div className='form-flow-list-table-container'>
        <table className="form-flow-list-table">
          <thead>
            <tr>
              <th>Flow Name</th>
              <th>Flow ID</th>
              <th>Status</th>
              <th>Flow Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {formFlows.map((flow) => (
              <tr key={flow.id}>
                <td>{flow.name}</td>
                <td>{flow.id}</td>
                <td>{flow.status}</td>
                <td>{flow.type}</td>
                <td>
                  <button className="form-flow-edit-button" onClick={() => handleEditClick(flow)}>Edit</button>
                  <button className="form-flow-delete-button" onClick={() => handleDeleteClick(flow)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
      />
      <div className='form-flows-action-buttons'>
        <Link to="/NewFormFlow">
          <button className="add-new-form-flow-button">Add New Form Flow</button>
        </Link>
      </div>
    </div>
  );
};

export default FormFlows;
