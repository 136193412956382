import React from 'react';

const FormFlowsScreens = ({ flow, onAddScreen, onSelectScreen, onRemoveScreen }) => {
  return (
    <div className="FormFlowsScreens-section">
      <button onClick={onAddScreen}>+ Add new</button>
      <div className="FormFlowsScreens-list">
        {flow.screens.map(screen => (
          <div key={screen.id} className="screen-item">
            <span onClick={() => onSelectScreen(screen.id)}>
              {screen.title || 'Untitled Screen'}
            </span>
            <button onClick={() => onRemoveScreen(screen.id)}>X</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormFlowsScreens;
