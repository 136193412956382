import React, { useState } from 'react';
import './VariableInput.css'; // Ensure this path is correct for your project structure

const VariableInput = ({ variables, onAddVariable, onRemoveVariable, onAddVariableToBody, bodyText }) => {
  const [variable, setVariable] = useState('');
  const handleAddClick = () => {
    if (variable.trim() !== '') {
      const updatedBodyText = bodyText + `{{${variable}}}`; // Update the body text with the new variable
      onAddVariableToBody(updatedBodyText); // Update the body text in real-time
      onAddVariable(variable);
      setVariable('');
    }
  };

  return (
    <div className="variable-input-container">
      <label>Add Variables:</label>
      <div>
        <input
          type="text"
          value={variable}
          onChange={(e) => setVariable(e.target.value)}
          className="variable-input" // Add this class to style the input
        />
        <button onClick={handleAddClick} className="add-btn">Add</button> {/* Add this class to style the button */}
      </div>
      <ul>
        {variables.map((variable, index) => (
          <li key={index} className="variable-item">
          <span className="variable-index">{`{{${index + 1}}}`}</span> {variable}
          <button onClick={() => onRemoveVariable(index)} className="remove-btn">Remove</button>
        </li>
        ))}
      </ul>
    </div>
  );
};

export default VariableInput;
