import React from 'react';
import * as XLSXLib from 'xlsx'; // Rename the import
import { saveAs } from 'file-saver';

import './ImportResultsPopup.css'

const ImportResultsPopup = ({ results, onClose }) => {


  const handleExportToExcel = () => {
  if (results && results.length > 0) {
    // Create a new workbook
    const wb = XLSXLib.utils.book_new();
    const ws = XLSXLib.utils.json_to_sheet(results);

    // Add the worksheet to the workbook
    XLSXLib.utils.book_append_sheet(wb, ws, 'Import Results');

    // Create a binary ArrayBuffer from the workbook
    const arrayBuffer = XLSXLib.write(wb, { bookType: 'arraybuffer' });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Use the `saveAs` function to trigger the download
    saveAs(blob, 'import_results.xlsx');
  } else {
    console.error('No results to export.');
  }
};


  if (!Array.isArray(results)) {
    return (
      <div className="import-results-popup">
        <h2>Import Results</h2>
        <p>No results to display</p>
        <button onClick={onClose}>Close</button>
      </div>
    );
  }
  console.log(results);
  const totalRows = results.length;
  const succeededCount = results.filter((result) => result.status === 'Succeeded').length;
  const failedCount = results.filter((result) => result.status === 'Failed').length;
  // const existCount = results.filter((result) => result.status === 'Exist').length;
  const existCount = results.filter((result) => result.status.includes('Exist')).length;

  const succeededPercentage = (succeededCount / totalRows) * 100;
  const failedPercentage = (failedCount / totalRows) * 100;
  const existPercentage = (existCount / totalRows) * 100;

  return (
    <div className="import-results-popup">
      <h2>Import Results</h2>
      <div className="summary-section">
        <h3>Summary:</h3>
        <p>Total Rows: {totalRows}</p>
        <p>Succeeded: {succeededCount} ({succeededPercentage.toFixed(2)}%)</p>
        <p>Failed: {failedCount} ({failedPercentage.toFixed(2)}%)</p>
        <p>Exist: {existCount} ({existPercentage.toFixed(2)}%)</p>
      </div>
      <div className="details-section">
        <h3>Details:</h3>
        <table>
          <thead>
            <tr>
              <th>Row</th>
              <th>Name</th>
              <th>Number</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{result.name}</td>
                <td>{result.number}</td>
                <td>{result.email}</td>
                <td className={result.status ? result.status.toLowerCase() : ''}>
                  {result.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <button onClick={handleExportToExcel}>Export to Excel</button> */}
      </div>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default ImportResultsPopup;
