import React, { useEffect } from 'react';
import { auth } from '../firebase';
import './ChatMessage.css';

const ChatMessage = ({ message, time, sender, from, to, id, orgazanizationNumber, userName, messageType }) => {

    // Use useEffect to log messages when component mounts or props change
    useEffect(() => {
        // console.log('Message:', message);
        // console.log('Organization Number:', orgazanizationNumber);
        // console.log('From:', from);
        // console.log('User Name:', userName);
        // console.log('Time:', time);

        return () => {
            // Clean up function if needed
        };
    }, [message, orgazanizationNumber, from, userName, time]);

    // Function to format the timestamp string
    const formatTimestamp = (timestampString) => {
        const timestamp = new Date(timestampString);

        const day = timestamp.getDate().toString().padStart(2, '0');
        const month = (timestamp.getMonth() + 1).toString().padStart(2, '0');
        const year = timestamp.getFullYear().toString();
        const hours = timestamp.getHours().toString().padStart(2, '0');
        const minutes = timestamp.getMinutes().toString().padStart(2, '0');
        const seconds = timestamp.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    return (
        <div className='chat-message'
            style={{
                alignSelf: from === orgazanizationNumber ? 'flex-end' : 'flex-start',
                backgroundColor: from === orgazanizationNumber ? '#dcf8c6' : '#fff'
            }}
        >
            <div className='chat-message-text'>
                <p>{message}</p>
            </div>
            <div className='chat-message-date'>
                <p>{time ? formatTimestamp(time) : 'No Time Available'}</p>
            </div>
            {from === orgazanizationNumber ? (
                <div className='chat-message-sender'><p>{userName}</p></div>
            ) : ""}
        </div>
    );
};

export default ChatMessage;
