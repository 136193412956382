import React from 'react';
import './FormFlowsPreview.css';

const FormFlowsPreview = ({ selectedScreen }) => {
  if (!selectedScreen) return null;

  const renderPreviewContent = (contentItem) => {
    switch (contentItem.type) {
      case 1.1: // Large Heading
      return(
        <div key={contentItem.id}>
      <p style={{fontWeight:'bold', fontSize:'36x'}}>{contentItem.content}</p> 
    </div>
      )
      case 1.2: // Small Heading
      return(
        <div key={contentItem.id}>
      <p style={{fontWeight:'bold', fontSize:'22px'}}>{contentItem.content}</p> 
    </div>
      )
      case 1.3: // Caption
      case 1.4: // Body
        return (
          <div
            key={contentItem.id}
            style={{ fontSize: contentItem.type === 1.1 ? '24px' : contentItem.type === 1.2 ? '16px' : '14px' }}
          >
            {contentItem.content}
          </div>
        );
      case 2.1: // Image
        return (
          <div key={contentItem.id}>
            <img src={contentItem.content} alt={contentItem.name} />
          </div>
        );
      case 3.1: // Short Answer
      case 3.2: // Paragraph
        return (
          <div key={contentItem.id}>
            <textarea placeholder={contentItem.name} readOnly />
          </div>
        );
      case 3.3: // Date Picker
        return (
          <div key={contentItem.id}>
            <input type="date" readOnly />
          </div>
        );
      case 4.1: // Single Choice
      return (
        <div key={contentItem.id}>
          <p>{contentItem.content}</p>
          {contentItem?.options?.map((option, index) => (
            <div key={index}>
              <input type="radio" id={`option-${index}`} name={`single-choice-${contentItem.id}`} />
              <label htmlFor={`option-${index}`}>{option}</label>
            </div>
          ))}
        </div>
      );
      case 4.2: // Multiple Choice
      return (
        <div key={contentItem.id}>
          <p>{contentItem.name}</p>
          {contentItem?.options?.map((option, index) => (
            <div key={index}>
              <input type="checkbox" id={`option-${index}`} name={`multiple-choice-${contentItem.id}`} />
              <label htmlFor={`option-${index}`}>{option}</label>
            </div>
          ))}
        </div>
      );
      case 4.3: // Dropdown
      case 4.4: // Opt-in
        return (
          <div key={contentItem.id}>
            <textarea
              placeholder={`Enter ${contentItem.name.toLowerCase()} options`}
              readOnly
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="FormFlowsPreview-section">
      <h2>Preview</h2>
      <div  className="FormFlowsPreview-content">
        <h3 style={{textAlign:'center'}}>{selectedScreen.title}</h3>
        {selectedScreen.content.map(renderPreviewContent)}
        <button>{selectedScreen.button}</button>
      </div>
    </div>
  );
};

export default FormFlowsPreview;
