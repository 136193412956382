import React, { useState, useEffect, useCallback } from 'react';
import ConditionGroup from './ConditionGroup';
import TemplatesButtonClickedHandler from './TemplatesButtonClickedHandler';

const IncomingMessageTrigger = ({ organization, onChange, initialConfig = {} }) => {
  const [messageType, setMessageType] = useState(initialConfig?.messageType || 'regular');
  const [conditionGroups, setConditionGroups] = useState(initialConfig.conditionGroups || [{ logicOperator: 'AND', conditions: [{ operator: 'equals', value: '' }] }]);
  const [groupRelations, setGroupRelations] = useState(initialConfig.groupRelations || []);
  const [selectedTemplates, setSelectedTemplates] = useState(initialConfig.selectedTemplates || []);
  const [selectedButtons, setSelectedButtons] = useState(initialConfig.selectedButtons || []);

  // Function to trigger onChange only if state has changed
  const triggerOnChange = useCallback(() => {
    const newConfig = { messageType, conditionGroups, groupRelations, selectedTemplates, selectedButtons };
    if (JSON.stringify(newConfig) !== JSON.stringify(initialConfig)) {
      onChange(newConfig);
    }
  }, [messageType, conditionGroups, groupRelations, selectedTemplates, selectedButtons, onChange, initialConfig]);

  useEffect(() => {
    triggerOnChange();
  }, [triggerOnChange]);

  const handleMessageTypeChange = (e) => {
    setMessageType(e.target.value);
  };

  const handleGroupChange = (index, updatedGroup) => {
    setConditionGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[index] = updatedGroup;
      return newGroups;
    });
  };

  const addGroup = () => {
    setConditionGroups(prevGroups => [...prevGroups, { logicOperator: 'AND', conditions: [{ operator: 'equals', value: '' }] }]);
    setGroupRelations(prevRelations => [...prevRelations, 'AND']);
  };

  const handleDeleteGroup = (index) => {
    setConditionGroups(prevGroups => prevGroups.filter((_, i) => i !== index));
    setGroupRelations(prevRelations => prevRelations.filter((_, i) => i !== index - 1));
  };

  const handleAddCondition = (groupIndex) => {
    setConditionGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex].conditions.push({ operator: 'equals', value: '' });
      return newGroups;
    });
  };

  const handleGroupRelationChange = (index, value) => {
    setGroupRelations(prevRelations => {
      const newRelations = [...prevRelations];
      newRelations[index] = value;
      return newRelations;
    });
  };

  const handleTemplatesChange = (selectedOptions, buttons) => {
    setSelectedTemplates(selectedOptions);
    setSelectedButtons(buttons);
  };

  return (
    <div className="incoming-message-trigger">
      <label>
        Message Type:
        <select value={messageType} onChange={handleMessageTypeChange}>
          <option value="regular">Regular</option>
          <option value="template">Template</option>
        </select>
      </label>

      {messageType === 'regular' && (
        <div className="condition-groups">
          {conditionGroups.map((group, index) => (
            <div key={index} className="condition-group-wrapper">
              <ConditionGroup
                group={group}
                onGroupChange={(updatedGroup) => handleGroupChange(index, updatedGroup)}
                onDeleteGroup={() => handleDeleteGroup(index)}
                onAddCondition={() => handleAddCondition(index)}
                isRemovable={index > 0}
              />
            </div>
          ))}
          <div className="add-group-wrapper">
            <button className='condition-action-add-group-wrapper' type="button" onClick={addGroup}>Add Group</button>
            {conditionGroups.length > 0 && (
              <select
                value={groupRelations[groupRelations.length - 1] || 'AND'}
                onChange={(e) => handleGroupRelationChange(groupRelations.length - 1, e.target.value)}
                className="group-relation-select"
              >
                <option value="AND">AND</option>
                <option value="OR">OR</option>
              </select>
            )}
          </div>
        </div>
      )}

      {messageType === 'template' && (
        <TemplatesButtonClickedHandler
          organization={organization}
          onTemplatesChange={handleTemplatesChange}
          initialTemplates={initialConfig.selectedTemplates || []}
          initialButtons={initialConfig.selectedButtons || []}
        />
      )}
    </div>
  );
};

export default IncomingMessageTrigger;
