import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import './Templates.css';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import ConfirmationDialog from '../ConfirmationDialog';

const Templates = (currentUser) => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]); // State for templates data
  const [openDialog, setOpenDialog] = useState(false); // State for template details dialog
  const [selectedTemplate, setSelectedTemplate] = useState(null); // State for the selected template
  const [templateName, setTemplateName] = useState(''); // State for the template name
  const [status, setStatus] = useState(''); // State for the template status
  const [templateType, setTemplateType] = useState(''); // State for the template type


  useEffect(() => {
    const fetchData = async () => {
      let orgID = currentUser.currentUser.organization;
      
      try {
        const response = await axios.get('https://gambot.azurewebsites.net/api/Webhooks/GetAllTemplates', {
          params: {
            organizationID: orgID
          }
        });
        console.log(response.data);
        setTemplates(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleDeleteClick = (template) => {
    setSelectedTemplate(template);
    setOpenDialog(true);
  };

  const handleEditClick = (template) => {
    console.log(template);
    // const thisTemplateQueryString = new URLSearchParams(template).toString();
    const thisTemplateQueryString = new URLSearchParams({
      "organization": currentUser.currentUser.organization,
      ...template,
      components: JSON.stringify(template.components)
    })
    navigate(`/UpdateTemplate/?${thisTemplateQueryString}`);
  };
  const handleConfirmDelete = () => {
    // Call your delete template API here with selectedTemplate.id
    var payload = {
      "organization": currentUser.currentUser.organization,
      "templateId": selectedTemplate.Id,
      "templateName": selectedTemplate.name,
    }
    console.log(payload);
    axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteTemplateByID", payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
    setOpenDialog(false);
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handle template name change
  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  // Handle status change
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // Handle template type change
  const handleTemplateTypeChange = (event) => {
    setTemplateType(event.target.value);
  };

  return (
    <div className="template-list-container">
      <h1 className="template-list-header">Templates</h1>
      {/* Add a search input if needed */}
      {/* <input type="text" placeholder="Search Templates" className="search-input" /> */}
      <div className='template-list-table-conatiner'>
      <table className="template-list-table">
        <thead>
          <tr>
            <th>Template Name</th>
            <th>Template ID</th>
            <th>Status</th>
            <th>Template Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => (
            <tr key={template.id}>
              <td>{template.name}</td>
              <td>{template.Id}</td>
              <td>{template.status}</td>
              <td>{template.category}</td>
              {/* Add actions buttons for editing and deleting templates */}
              <td>
                <button className="template-edit-button" onClick={() => handleEditClick(template)}>Edit</button>
                <button className="template-delete-button" onClick={() => handleDeleteClick(template)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDelete}
      />
      <div className='templates-action-buttons'>
        <Link to="/NewMessageTemplate">
          <button className="add-new-template-button">Add New Template</button>
        </Link>
      </div>
    </div>
  );
};

export default Templates;