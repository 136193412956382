import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import InputIcon from '@mui/icons-material/Input';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import InfoIcon from '@mui/icons-material/Info';

// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
// import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <HomeIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Login',
    path: '/Login',
    icon: <InputIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Examples',
    path: '/examples',
    icon: <WorkOutlineIcon/>,
    cName: 'nav-text'
  },
  {
    title: 'About',
    path: '/aboutus',
    icon: <InfoIcon />,
    cName: 'nav-text'
  },
//   {
//     title: 'Messages',
//     path: '/messages',
//     icon: <FaIcons.FaEnvelopeOpenText />,
//     cName: 'nav-text'
//   },
//   {
//     title: 'Support',
//     path: '/support',
//     icon: <IoIcons.IoMdHelpCircle />,
//     cName: 'nav-text'
//   }
];